import { handleResponse } from './handleResponse';
//'https://intouchdev.mapmyindia.com/tsrtc/api';
const BASE_URL = 'https://intouch.mapmyindia.com/tsrtc/api';
export const CommonService = {
  getAllStops,
  getAllStopsAirport,
  getServiceType,
  betweenTwoLocations,
  searchByServiceId,
  allVehicles,
  searchByVehicleId,
  tripsDetails,
  vehiclesLiveDetails,
  nearByStops,
  savefeedback,
  savesos,
  getLocationAddress,
  getAllServices,
};
function authHeader() {
  let myHeaders = new Headers();
  // myHeaders.append('Authorization','Bearer 07108581-4997-4131-94d3-25f129435617');
  //myHeaders.append('Content-Type', 'application/json');
  return myHeaders;
}
function getAllStops(operationType) {
  let requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  //'https://intouchdev.mapmyindia.com/tsrtc/api/stops?operationType=2'
  return fetch(
    `${BASE_URL}/stops?operationType=${operationType}`,
    requestOptions
  ).then(handleResponse);
}

function getAllStopsAirport(operationType) {
  let requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  //'https://intouchdev.mapmyindia.com/tsrtc/api/stops?operationType=2&isAirport=1'
  return fetch(
    `${BASE_URL}/stops?operationType=${operationType}&isAirport=1`,
    requestOptions
  ).then(handleResponse);
}

function getServiceType(operationType) {
  let requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(
    `${BASE_URL}/services/serviceType?operationType=${operationType}`,
    requestOptions
  ).then(handleResponse);
}

function betweenTwoLocations(from, to, operationType, serviceType) {
  let requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  //betweenTwoLocations?from=295665&to=295670&operationType=2&serviceType=
  return fetch(
    `${BASE_URL}/trips/betweenTwoLocations?from=` +
      from +
      '&to=' +
      to +
      '&operationType=' +
      operationType +
      '&serviceType=' +
      serviceType,
    requestOptions
  ).then(handleResponse);
  //'&startTime=1653005933&endTime=1653284973',
}

function getAllServices(operationType = 1) {
  let requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(
    `${BASE_URL}/services?operationType=${operationType}`,
    requestOptions
  ).then(handleResponse);
}

function searchByServiceId(serviceId, operationType, startTime, endTime) {
  let requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  //trips/service/41762?startTime=&endTime=
  // serviceId +
  // '?startTime=' +
  // startTime +
  // '&endTime=' +
  // endTime
  return fetch(
    `${BASE_URL}/trips/service/${serviceId}?operationType=${operationType}`,
    requestOptions
  ).then(handleResponse);
}
function allVehicles(operationType = 1, startTime, endTime) {
  let requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(
    `${BASE_URL}/vehicles?operationType=${operationType}`,
    requestOptions
  ).then(handleResponse);
}

function searchByVehicleId(vehicleId, operationType = 1) {
  let requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  //trips/vehicleNumber/67808
  return fetch(
    `${BASE_URL}/trips/vehicleNumber/` +
      vehicleId +
      '?operationType=' +
      operationType,
    requestOptions
  ).then(handleResponse);
}

function tripsDetails(tripId, operationType) {
  let requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  //trips/details/304615
  return fetch(
    `${BASE_URL}/trips/details/${tripId}?operationType=${operationType}`,
    requestOptions
  ).then(handleResponse);
}
function vehiclesLiveDetails(vehicleId) {
  let requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  //vehicles/67808
  return fetch(`${BASE_URL}/vehicles/` + vehicleId, requestOptions).then(
    handleResponse
  );
}

function nearByStops(long, lat, maxDistance, limit) {
  let myHeaders = new Headers();
  let requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };
  // https://intouchdev.mapmyindia.com/tsrtc/api/stops/nearby?longitude=77.2689539194107&latitude=28.550666179405972&maxDistance=5&limit=20
  return fetch(
    `${BASE_URL}/stops/nearby?longitude=${long}&latitude=${lat}&maxDistance=${maxDistance}&limit=${limit}`,
    requestOptions
  ).then(handleResponse);
}

function savefeedback(feed) {
  var formdata = new FormData();

  for (let key in feed) {
    formdata.append(`${key}`, `${feed[key]}`);
  }

  const requestOptions = {
    method: 'POST',
    //headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: formdata,
    //body: JSON.stringify(feed),
  };

  return fetch(`${BASE_URL}/feedback`, requestOptions).then(handleResponse);
}
function savesos(sosData) {
  // const requestOptions = {
  //   method: 'POST',
  //   headers: { 'Content-Type': 'application/json' },
  //   body: JSON.stringify(sosData),
  // };
  var formdata = new FormData();

  for (let key in sosData) {
    formdata.append(`${key}`, `${sosData[key]}`);
  }

  const requestOptions = {
    method: 'POST',
    body: formdata,
  };

  return fetch(`${BASE_URL}/sos`, requestOptions).then(handleResponse);
}

function getLocationAddress(lat, lng) {
  //'https://apis.mapmyindia.com/advancedmaps/v1/7151sz2bhyytjxy3xdimzu2hx9vqoyb7/rev_geocode?lat=28.4421257&lng=77.0652025'

  let myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  let requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };

  return fetch(
    `https://apis.mapmyindia.com/advancedmaps/v1/d088323a3a8ae9d1e8a08aa36f2b8a5c/rev_geocode?lat=${lat}&lng=${lng}`,
    requestOptions
  ).then(handleResponse);
}
