import React from 'react';

import { Link } from 'react-router-dom';

import './../sass/Header.scss';
import logo from './../assets/logo1x.png';

export const Header = ({ handleToggleAppView, isAppView }) => {
  return (
    <header className="header-section">
      <div className="top-header">
        <div className="page-title">
          <h1>Welcome</h1>
        </div>
        <div className="action-section">
          <Link to="/about">
            <button>
              <span className="material-symbols-outlined">info </span>
            </button>
          </Link>
          <Link to="/notifications">
            <button>
              <span className="material-symbols-outlined">notifications</span>
            </button>
          </Link>
          <button onClick={handleToggleAppView}>
            {isAppView ? (
              <span className="material-symbols-outlined">grid_view </span>
            ) : (
              <span className="material-symbols-outlined">
                format_list_bulleted
              </span>
            )}
          </button>
        </div>
      </div>
      <div className="bottom-header">
        <div className="logo-section">
          <img src={logo} alt="logo" />
        </div>
        <div className="logo-info">
          <h2>TSRTC </h2>
          <p>Telengana State Road Transport Corporation</p>
        </div>
      </div>
    </header>
  );
};
