import airport from './assets/homepage/airport.svg';
import busstop from './assets/homepage/busstop.svg';
import emergency from './assets/homepage/emergency.svg';
import feedback from './assets/homepage/feedback.svg';
import route from './assets/homepage/route.svg';
import SearchBusNumber from './assets/homepage/SearchBusNumber.svg';
import searchbyservicenumber from './assets/homepage/searchbyservicenumber.svg';
import ticket from './assets/homepage/ticket.svg';

import { AirportServicePage } from './pages/AirportServicePage';
import { SearchByBusNumberPage } from './pages/SearchBusNumberPage';
import { SearchByServiceNumberPage } from './pages/SearchByServiceNumberPage';
import { ReservationBookTicketPage } from './pages/ReservationBookTicketPage';
import { SearchFromToPage } from './pages/SearchFromToPage';
import { SearchNearestBusStopPage } from './pages/SearchNearestBusStopPage';
import { EmergencyPage } from './pages/EmergencyPage';
import { FeedbackPage } from './pages/FeedbackPage';

export const Routes = {
  intra: {
    searchFromTo: {
      icon: route,
      title: 'Search Between From-To',
      description: 'Lorem ipsum dolor sit amet, consectetur',
      path: '/searchFromTo',
      Comp: SearchFromToPage,
      // pageTitle: 'Search Route',
    },
    airportService: {
      icon: airport,
      title: 'Airport Service',
      description: 'Lorem ipsum dolor sit amet, consectetur',
      path: '/airportService',
      Comp: AirportServicePage,
    },
    searchBusNumber: {
      icon: SearchBusNumber,
      title: 'Search By Bus/ Route Number',
      description: 'Lorem ipsum dolor sit amet, consectetur',
      path: '/searchBusNumber',
      Comp: SearchByBusNumberPage,
    },
    searchNearestBusStop: {
      icon: busstop,
      title: 'Your Nearest Bus Stop',
      description: 'Lorem ipsum dolor sit amet, consectetur',
      path: '/searchNearestBusStop',
      Comp: SearchNearestBusStopPage,
    },
    emergency: {
      icon: emergency,
      title: 'Emergency',
      description: 'Lorem ipsum dolor sit amet, consectetur',
      path: '/emergency',
      Comp: EmergencyPage,
      pageTitle: 'Emergency',
    },
    feedback: {
      icon: feedback,
      title: 'Feedback',
      description: 'Lorem ipsum dolor sit amet, consectetur',
      path: '/feedback',
      Comp: FeedbackPage,
      pageTitle: 'Feedback',
    },
  },
  inter: {
    searchFromTo: {
      icon: route,
      title: 'Search Between From-To',
      description: 'Lorem ipsum dolor sit amet, consectetur',
      path: '/searchFromTo',
      Comp: SearchFromToPage,
      // pageTitle: 'Search Route',
    },
    searchNearestBusStop: {
      icon: busstop,
      title: 'Your Nearest Bus Stop',
      description: 'Lorem ipsum dolor sit amet, consectetur',
      path: '/searchNearestBusStop',
      Comp: SearchNearestBusStopPage,
    },
    searchByServiceNumber: {
      icon: searchbyservicenumber,
      title: 'Search By Service Number',
      description: 'Lorem ipsum dolor sit amet, consectetur',
      path: '/searchByServiceNumber',
      Comp: SearchByServiceNumberPage,
      pageTitle: 'Search by Service No.',
    },
    searchBusNumber: {
      icon: SearchBusNumber,
      title: 'Search By Bus Number',
      description: 'Lorem ipsum dolor sit amet, consectetur',
      path: '/searchBusNumber',
      Comp: SearchByBusNumberPage,
    },
    reservationBookTicket: {
      icon: ticket,
      title: 'Reservation Book Ticket',
      description: 'Lorem ipsum dolor sit amet, consectetur',
      path: '/reservationBookTicket',
      Comp: ReservationBookTicketPage,
    },
    emergency: {
      icon: emergency,
      title: 'Emergency',
      description: 'Lorem ipsum dolor sit amet, consectetur',
      path: '/emergency',
      Comp: EmergencyPage,
      pageTitle: 'Emergency',
    },
    feedback: {
      icon: feedback,
      title: 'Feedback',
      description: 'Lorem ipsum dolor sit amet, consectetur',
      path: '/feedback',
      Comp: FeedbackPage,
      pageTitle: 'Feedback',
    },
  },
};
