import accident from '../assets/emergency/accident.svg';
import medical from '../assets/emergency/medical.svg';
import reportBreakdown from '../assets/emergency/report.svg';
import women from '../assets/emergency/women.svg';
import call from '../assets/emergency/call.svg';
import send from '../assets/emergency/send.svg';

export let emergencyFeedbackData = [
  {
    title: 'Women Safety',
    imgSrc: women,
    optionValue: '1',
  },
  {
    title: 'Report Breakdown',
    imgSrc: reportBreakdown,
    optionValue: '2',
  },
  {
    title: 'Medical Assistance',
    imgSrc: medical,
    optionValue: '3',
  },
  {
    title: 'Report Accident',
    imgSrc: accident,
    optionValue: '4',
  },
];

export let contactData = [
  {
    title: 'Police',
    value: 'call police',
    imgSrc: call,
    phNum: `tel:100`,
  },
  {
    title: 'Ambulance',
    value: 'call ambulance',
    imgSrc: call,
    phNum: `tel:108`,
  },
  {
    title: 'Send SMS',
    value: 'send sms',
    imgSrc: send,
    phNum: `sms:999`,
  },
];
