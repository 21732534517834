import locationMarker from './../assets/search/location_marker.svg';
import direction from './../assets/search/direction.svg';
import airport from './../assets/search/airport_icon.svg';

export const SearchList = function ({
  type = null,
  data = [],
  handleClick = () => {},
  selectedServiceTypes = null,
}) {
  // IMP: maxHeight -> 183 (74 + 10 + 83 + 16) + 5 (buffer)
  return (
    <>
      {(type === 'recent' || type === 'recentBusHistory') &&
        data &&
        data?.length > 0 && (
          <div
            className={`history-heading ${
              type === 'recentBusHistory' ? 'p-2' : ''
            }`}
            style={{ background: type === 'recentBusHistory' ? '#fafafa' : '' }}
          >
            <h2>Recent Trip History</h2>
          </div>
        )}

      <ul
        className={`search-list scroll-container overflow-auto ${
          //type !== 'recent' ?  'service-list' : ''
          type === 'recent'
            ? ''
            : type == 'recentBusHistory'
            ? ''
            : 'service-list'
        }`}
        style={{
          maxHeight: `${
            type === 'recent'
              ? 'calc(100vh - 220px)'
              : type === 'recentBusHistory'
              ? 'calc(100vh - 115px)'
              : 'calc(100vh - 188px)'
          }`,
        }}
      >
        {data &&
          data.length > 0 &&
          data.map((d) => {
            if (type === 'serviceType') {
              return (
                <li key={d.id} onClick={() => handleClick(d)}>
                  <p>
                    <input
                      className="custom-input"
                      type="checkbox"
                      value={d.id}
                      id={`check-${d.id}`}
                      onChange={() => handleClick(d)}
                      checked={selectedServiceTypes.some((s) => s.id === d.id)}
                    />
                    <label className="" htmlFor={`check-${d.id}`}>
                      {d.name}
                    </label>
                  </p>
                  <button>
                    <img src={direction} alt="direction" />
                  </button>
                </li>
              );
            } else if (type === 'recentBusHistory') {
              return (
                <li key={d.id} onClick={() => handleClick(d)} className="px-2">
                  <p>
                    <span className="material-symbols-outlined">schedule</span>
                    {d.id}
                  </p>
                  <button
                    className="p-2 rounded-4"
                    style={{ background: '#FAFAFA' }}
                  >
                    <span className="">{d.serviceType}</span>
                  </button>
                </li>
              );
            } else {
              return (
                <li key={d.id} onClick={() => handleClick(d)}>
                  <p>
                    {' '}
                    {type === 'recent' ? (
                      <span className="material-symbols-outlined">
                        schedule
                      </span>
                    ) : type === 'airport' ? (
                      <img src={airport} alt="airportMarker" />
                    ) : (
                      <img src={locationMarker} alt="locationMarker" />
                    )}
                    {d?.name || ''}
                  </p>{' '}
                  <button>
                    {' '}
                    <span className="material-symbols-outlined">
                      {' '}
                      north_east
                    </span>
                  </button>
                </li>
              );
            }
          })}
      </ul>
    </>
  );
};
