import React, { useState, useRef, useEffect } from 'react';
import {
  NavHome,
  SelectCard,
  SearchLocation,
  SelectServiceType,
  SearchResult,
  FilterSection,
  CommonSectionLRNav,
  RecentTripHistory,
} from './../components';
import Offcanvas from 'react-bootstrap/Offcanvas';

import { arrayOfObjectsEqual, getGeoLocation } from './../common/utils';

import { CommonService } from '../services/api';

import useLocalStorage from '../hooks/useLocalStorage';

import filterSvg from './../assets/filter.svg';
import backgroundGraphicSvg from './../assets/background-graphic.svg';

export const SearchFromToPage = () => {
  const [data, setData] = useState([]);
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const [isSearchLocationOpen, setIsSearchLocationOpen] = useState(false);
  const [isSelectServiceTypeOpen, setIsSelectServiceTypeOpen] = useState(false);
  const [serviceTypes, setServiceTypes] = useState([]);

  const [allStopsData, setAllStopsData] = useState([]);

  const [currentLocation, setCurrentLocation] = useState({
    address: '',
    latlng: '',
  });
  ////
  const [selectedTripData, setSelectedTripData] = useState(null);
  const [shouldTripMapComOpen, setShouldTripMapComOpen] = useState(false);
  const initialRender = useRef(true);

  useEffect(() => {
    if (!initialRender.current) {
      handleShouldTripMapComOpen();
    }
    initialRender.current = false;
  }, [selectedTripData]);
  ////
  const [selectedOperationType, _] = useLocalStorage('operationType', '_');
  const [recentTripFromToHistory, setRecentTripFromToHistory] = useLocalStorage(
    'recentTripFromToHistory',
    []
  );

  const [params, setParams] = useState({
    from: null,
    to: null,
    serviceType: [],
  });

  const [filterParams, setFilterParams] = useState({
    time: [],
    busType: [],
  });

  let operationType =
    selectedOperationType === 'inter'
      ? 1
      : selectedOperationType === 'intra'
      ? 2
      : 1;

  useEffect(() => {
    CommonService.getServiceType(operationType).then((data) => {
      setServiceTypes(data);
    });
  }, []);

  useEffect(() => {
    CommonService.getAllStops(operationType).then((result) => {
      setAllStopsData(result);
    });
  }, []);

  useEffect(() => {
    getGeoLocation()
      .then((location) => {
        let { latitude, longitude } = location;
        CommonService.getLocationAddress(latitude, longitude).then((res) => {
          //console.log(res);
          setCurrentLocation({
            address: res.results[0].formatted_address,
            latlng: `${res.results[0].lat},${res.results[0].lng}`,
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const paramType = useRef(null);

  const handleUpdateParams = function (value) {
    setParams({ ...params, [paramType.current]: value });
  };

  const handleShouldTripMapComOpen = () => {
    setShouldTripMapComOpen(!shouldTripMapComOpen);
  };

  const handleFilterOpen = () => {
    setIsFilterSidebarOpen(!isFilterSidebarOpen);
  };
  const handleSearchLocationOpen = () => {
    setIsSearchLocationOpen(!isSearchLocationOpen);
  };
  const handleSelectServiceTypeOpen = () => {
    setIsSelectServiceTypeOpen(!isSelectServiceTypeOpen);
  };

  const handleSelectServiceType = function (selectedServiceTypes = []) {
    handleSelectServiceTypeOpen();
    handleUpdateParams(selectedServiceTypes);
  };

  const getBetweenTwoLocationDataAndRender = function ({
    from,
    to,
    serviceType,
    operationType,
  }) {
    let { id: fromId } = from;
    let { id: toId } = to;
    let serviceTypeId = '';
    if (!isAirportIncluded && serviceType && serviceType?.length > 0) {
      serviceTypeId = serviceType.map((d) => d.id).join(',');
    }

    // getBetweenTwoLocationDataAndRender(295665, 295670, 2, serviceTypeId);
    //  fromId, toId, operationType, serviceTypeId
    CommonService.betweenTwoLocations(
      fromId,
      toId,
      operationType,
      serviceTypeId
    ).then((data) => {
      //console.log('data', data);

      if (data.length > 0) {
        // persist to local storage
        let isExists = recentTripFromToHistory.some((d) => {
          let flag = false;

          if (
            d.from.id === fromId &&
            d.to.id === toId &&
            d.operationType === operationType &&
            arrayOfObjectsEqual(d.serviceType, serviceType)
          ) {
            flag = true;
          }

          return flag;
        });
        if (!isExists) {
          if (recentTripFromToHistory.length === 10) {
            setRecentTripFromToHistory([
              ...recentTripFromToHistory.slice(1),
              {
                from,
                to,
                serviceType,
                operationType,
              },
            ]);
          } else {
            setRecentTripFromToHistory([
              ...recentTripFromToHistory,
              {
                from,
                to,
                serviceType,
                operationType,
              },
            ]);
          }
        }

        setData(data);
      } else {
        setData(-1);
      }
    });
  };
  //console.log('recentTripFromToHistory', recentTripFromToHistory);

  const handleSubmit = function () {
    if (!params.from || !params.to) return;
    getBetweenTwoLocationDataAndRender({ ...params, operationType });
  };

  const handleRecentTripFromToClick = function ({ operationType, ...data }) {
    setParams(data);
    getBetweenTwoLocationDataAndRender({ ...data, operationType });
  };

  const getFilteredData = function (data) {
    if (isAirportIncluded) return data;
    if (filterParams.busType.length === 0 && filterParams.time.length === 0) {
      return data;
    }
    return data;
  };

  const handleCardClick = (data = null) => {
    //console.log('card click is performend - ', data);
    if (shouldTripMapComOpen) {
      setSelectedTripData(data); //if already opened then set tripselected data to null
    } else {
      setSelectedTripData(data);
    }
  };

  const handleSwapLocation = function () {
    if (params.to || params.from) {
      setParams({ ...params, from: params.to, to: params.from });
    }
  };

  let isAirportIncluded = false;
  if (
    operationType === 2 &&
    params.from &&
    params.from.name.toLowerCase().includes('airport')
  ) {
    isAirportIncluded = true;
  }
  if (
    operationType === 2 &&
    params.to &&
    params.to.name.toLowerCase().includes('airport')
  ) {
    isAirportIncluded = true;
  }

  return (
    <div className="tsrtc-container position-relative">
      {isSearchLocationOpen && (
        <SearchLocation
          openCloseHandler={handleSearchLocationOpen}
          clickHandler={handleUpdateParams}
          currentLocation={currentLocation ? currentLocation : null}
          allStopsData={allStopsData}
          params={{ from: params.from?.id ?? null, to: params.to?.id ?? null }}
          paramTypeToFilter={paramType.current === 'to' ? 'from' : 'to'}
        />
      )}
      {!isAirportIncluded && isSelectServiceTypeOpen && (
        <SelectServiceType
          title="Select Service Type"
          handleSelectServiceType={handleSelectServiceType}
          prevSelectedServiceType={params.serviceType}
          serviceTypes={serviceTypes}
        />
      )}

      {shouldTripMapComOpen && (
        <CommonSectionLRNav
          navTitle={
            selectedTripData
              ? `${selectedTripData.from.name} to ${selectedTripData.to.name}`
              : ''
          }
          handleShouldTripMapComOpen={handleCardClick}
          tripDetails={selectedTripData}
        />
      )}

      <NavHome
        title={'Search Route'}
        icon={filterSvg}
        handler={handleFilterOpen}
      />

      {!isAirportIncluded && (
        <Offcanvas
          show={isFilterSidebarOpen}
          onHide={handleFilterOpen}
          scroll={false}
          backdrop={true}
          placement="end"
          style={{ top: '52px', maxWidth: '360px' }}
        >
          <Offcanvas.Body>
            <FilterSection
              isFilterSidebarOpen={isFilterSidebarOpen}
              handleFilterOpen={handleFilterOpen}
              type="fromTo"
              busData={[...serviceTypes]}
              handleApply={(params) => {
                setFilterParams(params);
              }}
              prevFilterParams={filterParams}
            />
          </Offcanvas.Body>
        </Offcanvas>
      )}

      <div className="search-section">
        <div className="search-left">
          <div className="input-section">
            <div className="from-to-route">
              <div className="input-wrap">
                <SelectCard
                  title="From"
                  subTitle={params.from ? params.from.name : 'Location'}
                  handler={() => {
                    paramType.current = 'from';
                    handleSearchLocationOpen();
                  }}
                />
              </div>
              <div className="input-wrap">
                <SelectCard
                  title="To"
                  subTitle={params.to ? params.to.name : 'Location'}
                  handler={() => {
                    paramType.current = 'to';
                    handleSearchLocationOpen();
                  }}
                />
              </div>
              <div className="route-swap">
                <button onClick={handleSwapLocation}>
                  <span className="material-symbols-outlined">swap_horiz</span>
                </button>
              </div>
            </div>

            {!isAirportIncluded && (
              <div className="input-wrap">
                <SelectCard
                  title="Service Type"
                  subTitle={
                    params.serviceType && params.serviceType.length > 0
                      ? params.serviceType.map((d) => d.name).join('')
                      : 'Metro Luxury'
                  }
                  subTitleLength={30}
                  handler={() => {
                    paramType.current = 'serviceType';
                    handleSelectServiceTypeOpen();
                  }}
                />
              </div>
            )}

            <div className="search-bt">
              <button onClick={handleSubmit}>Search Bus</button>
            </div>

            {recentTripFromToHistory && recentTripFromToHistory.length > 0 && (
              <div
                className="trip-history overflow-auto scroll-container"
                style={{
                  height: `calc(100vh - ${!isAirportIncluded ? 350 : 260}px)`,
                }}
              >
                <div className="history-heading">
                  <h2>Recent Trip History</h2>
                </div>
                {recentTripFromToHistory
                  .filter((d) => d.operationType === operationType)
                  .reverse()
                  .map((d) => {
                    return (
                      <RecentTripHistory
                        data={d}
                        key={d.from.id + d.to.id + String(Math.random() * 100)}
                        handleClick={handleRecentTripFromToClick}
                      />
                    );
                  })}
              </div>
            )}
          </div>
        </div>
        <div className="right-section">
          <div
            className="search-blank"
            style={{ flexDirection: `${data === -1 ? 'column' : 'row'}` }}
          >
            {data && data?.length > 0 ? (
              <SearchResult
                data={getFilteredData(data)}
                handleClick={handleCardClick}
              />
            ) : data === -1 ? (
              <>
                <h5>Bus not found</h5>
                <img src={backgroundGraphicSvg} alt="background" />
              </>
            ) : (
              <img src={backgroundGraphicSvg} alt="background" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
