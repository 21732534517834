import React from 'react';

import currentLocationSvg from './../assets/search/current_location.svg';
import './../sass/CurrentLocation.scss';

export const CurrentLocation = ({ address = '', ...props }) => {
  return (
    <div className="current-location">
      <h4
        className={`${
          props.changeable ? 'd-flex justify-content-between' : ''
        }`}
      >
        <span>
          <img src={currentLocationSvg} alt="currentLocation" />
          Current Location
        </span>
        {props.changeable && (
          <span
            className="cursorPointer text-clr-primary"
            onClick={props.handler}
          >
            Change
          </span>
        )}
      </h4>
      <p>{address ? address : '.'}</p>
    </div>
  );
};
