import React, { useState, useRef, useEffect } from 'react';
import {
  NavHome,
  SelectCard,
  SearchLocation,
  SearchResult,
  CommonSectionLRNav,
} from './../components';

import { CommonService } from '../services/api';

import useLocalStorage from '../hooks/useLocalStorage';

import backgroundGraphicSvg from './../assets/background-graphic.svg';

export const AirportServicePage = () => {
  const [data, setData] = useState([]);
  const [isSearchLocationOpen, setIsSearchLocationOpen] = useState(false);

  const [allStopsData, setAllStopsData] = useState([]);

  ////
  const [selectedTripData, setSelectedTripData] = useState(null);
  const [shouldTripMapComOpen, setShouldTripMapComOpen] = useState(false);
  const initialRender = useRef(true);

  useEffect(() => {
    if (!initialRender.current) {
      handleShouldTripMapComOpen();
    }
    initialRender.current = false;
  }, [selectedTripData]);
  ////
  const [selectedOperationType, _] = useLocalStorage('operationType', '_');

  const [params, setParams] = useState({
    from: null,
    to: null,
  });

  let operationType =
    selectedOperationType === 'inter'
      ? 1
      : selectedOperationType === 'intra'
      ? 2
      : 1;

  useEffect(() => {
    CommonService.getAllStopsAirport(operationType).then((result) => {
      setAllStopsData(result);
    });
  }, []);

  const paramType = useRef(null);

  const handleUpdateParams = function (value) {
    setParams({ ...params, [paramType.current]: value });
  };

  const handleShouldTripMapComOpen = () => {
    setShouldTripMapComOpen(!shouldTripMapComOpen);
  };

  const handleSearchLocationOpen = () => {
    setIsSearchLocationOpen(!isSearchLocationOpen);
  };

  const getBetweenTwoLocationDataAndRender = function ({
    from,
    to,
    operationType,
  }) {
    let { id: fromId } = from;
    let { id: toId } = to;

    // getBetweenTwoLocationDataAndRender(295665, 295670, 2, serviceTypeId);
    //  fromId, toId, operationType
    CommonService.betweenTwoLocations(fromId, toId, operationType, '').then(
      (data) => {
        //console.log('data', data);
        if (data.length > 0) {
          setData(data);
        } else {
          setData([]);
        }
      }
    );
  };
  //console.log('recentTripFromToHistory', recentTripFromToHistory);

  const handleSubmit = function () {
    if (!params.from || !params.to) return;
    getBetweenTwoLocationDataAndRender({ ...params, operationType });
  };

  const handleCardClick = (data = null) => {
    //console.log('card click is performend - ', data);
    if (shouldTripMapComOpen) {
      setSelectedTripData(data); //if already opened then set tripselected data to null
    } else {
      setSelectedTripData(data);
    }
  };

  const handleSwapLocation = function () {
    if (params.to || params.from) {
      setParams({ ...params, from: params.to, to: params.from });
    }
  };

  return (
    <div className="tsrtc-container position-relative">
      {isSearchLocationOpen && (
        <SearchLocation
          openCloseHandler={handleSearchLocationOpen}
          clickHandler={handleUpdateParams}
          //d?.geozoneType === 'Airport'
          allStopsData={
            paramType.current === 'from'
              ? allStopsData.filter((d) => d)
              : allStopsData
          }
          params={{ from: params.from?.id ?? null, to: params.to?.id ?? null }}
          paramTypeToFilter={paramType.current === 'to' ? 'from' : 'to'}
          showRecentMost={false}
          type={paramType.current === 'from' ? 'airport' : null}
        />
      )}

      {shouldTripMapComOpen && (
        <CommonSectionLRNav
          navTitle={
            selectedTripData
              ? `${selectedTripData.from.name} to ${selectedTripData.to.name}`
              : ''
          }
          handleShouldTripMapComOpen={handleCardClick}
          tripDetails={selectedTripData}
          currentLocation={null}
        />
      )}

      <NavHome title={'Search by Airport'} />

      <div className="search-section">
        <div className="search-left">
          {/*  */}
          <div className="input-section">
            <div className="from-to-route">
              <div className="input-wrap">
                <SelectCard
                  title="From"
                  subTitle={params.from ? params.from.name : 'Location'}
                  handler={() => {
                    paramType.current = 'from';
                    handleSearchLocationOpen();
                  }}
                />
              </div>
              <div className="input-wrap">
                <SelectCard
                  title="To"
                  subTitle={params.to ? params.to.name : 'Location'}
                  handler={() => {
                    paramType.current = 'to';
                    handleSearchLocationOpen();
                  }}
                />
              </div>
              <div className="route-swap">
                <button onClick={handleSwapLocation}>
                  <span className="material-symbols-outlined">swap_horiz</span>
                </button>
              </div>
            </div>

            <div className="search-bt">
              <button onClick={handleSubmit}>Search Bus</button>
            </div>
          </div>
        </div>
        <div className="right-section">
          <div className="search-blank">
            {data && data.length > 0 ? (
              <SearchResult data={data} handleClick={handleCardClick} />
            ) : (
              <img src={backgroundGraphicSvg} alt="background" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
