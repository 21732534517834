import React, { useEffect, useState } from 'react';
import { feedbackData, emotionData } from '../staticData/feedbackData';
import { Link } from 'react-router-dom';
import { CommonService } from '../services/api';

export function FeedbackPage() {
  const [option, setOption] = useState('1');

  const handleOptionSet = (type) => {
    if (type === option) {
      return;
    }

    setOption(type);
  };
  //flex-grow-1
  return (
    <div
      className="container-fluid "
      style={{ padding: '0', margin: '0', height: '100vh' }}
    >
      <div
        className="d-flex m-0 mw-100"
        style={{ height: '100%', marginLeft: '0' }}
      >
        <div className="feedback__option ">
          {feedbackData.map((d, i) => {
            return (
              <div
                key={i}
                className={`${
                  option === `${i + 1}` ? 'feedback__option__selected' : ''
                } card`}
              >
                <div
                  className="card-body cursorPointer"
                  onClick={() => handleOptionSet(`${i + 1}`)}
                >
                  <div className="rounded-3">
                    <img src={d.imgSrc} alt="" />
                  </div>
                  <p className="card-text align-self-center">{d.title}</p>
                </div>
              </div>
            );
          })}
        </div>

        <div className="feedback__content ">
          {option === '1' && <FeedbackForm type="1" />}
          {option === '2' && <FeedbackForm type="2" />}
          {option === '3' && <FeedbackForm type="3" />}
        </div>
      </div>
    </div>
  );
}

function FeedbackForm({ type }) {
  const [userIp, setUserIp] = useState({
    number: '',
    desc: '',
    doj: '',
    service: {
      type: 'serviceNumber',
      number: '',
    },
    rate: null,
    type: type,
  });
  const [submitted, setSubmitted] = useState(null);

  const handleChange = (value, type) => {
    //console.log(type, value, userIp);

    if (type === 'number') {
      setUserIp({ ...userIp, number: value });
    } else if (type === 'servicetype') {
      setUserIp({
        ...userIp,
        service: { ...userIp.service, type: value },
      });
    } else if (type === 'serviceVehicleNumber') {
      setUserIp({
        ...userIp,
        service: { ...userIp.service, number: value },
      });
    } else if (type === 'doj') {
      setUserIp({
        ...userIp,
        doj: value,
      });
    } else if (type === 'desc') {
      setUserIp({
        ...userIp,
        desc: value,
      });
    } else if (type === 'rate') {
      setUserIp({
        ...userIp,
        rate: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //suceess -- submitted success fail
    if (!userIp.rate) {
      alert('Please select rating.');
    } else {
      let ratingValue = '';
      if (userIp.rate) {
        if (userIp.rate === 'Best') {
          ratingValue = 4;
        } else if (userIp.rate === 'Good') {
          ratingValue = 3;
        } else if (userIp.rate === 'Average') {
          ratingValue = 2;
        } else if (userIp.rate === 'Bad') {
          ratingValue = 1;
        }
      }

      let timestamp = Math.floor(
        new Date(...userIp.doj.split('-')).getTime() / 1000.0
      );
      //      console.log('timestamp - ', timestamp, ...userIp.doj.split('-'));
      let dataTobeSubmitted = {
        type: Number(userIp.type),
        mobile: userIp.number,
        [userIp.service.type]: userIp.service.number,
        timestamp: timestamp,
        rating: ratingValue,
        description: userIp.desc,
      };

      //console.log('dataTobeSubmitted - ', dataTobeSubmitted);

      CommonService.savefeedback(dataTobeSubmitted)
        .then((result) => {
          setSubmitted('success');
          setUserIp({
            number: '',
            desc: '',
            doj: '',
            service: {
              type: 'serviceNumber',
              number: '',
            },
            rate: null,
            type: type,
          });
        })
        .catch((err) => {
          setSubmitted('failed');
          alert(err);
        });
    }
  };

  useEffect(() => {
    let timerId = setTimeout(() => setSubmitted(null), 4000);

    return () => {
      clearTimeout(timerId);
    };
  }, [submitted]);

  return (
    <div className="w-70 pt-30 m-auto py-3 position-relative">
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="FormControlInput1" className="form-label">
            Mobile Number
          </label>
          <input
            type="number"
            maxLength="10"
            className="form-control"
            id="FormControlInput1"
            placeholder="Enter Mobile Number"
            required
            value={userIp.number}
            onChange={(e) => {
              handleChange(e.target.value, 'number');
            }}
          />
        </div>
        <div className="d-flex">
          <div className="form-check me-3">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              value="serviceNumber"
              checked={userIp.service.type === 'serviceNumber'}
              onChange={(e) => {
                handleChange(e.target.value, 'servicetype');
              }}
            />
            <label className="form-check-label" htmlFor="flexRadioDefault1">
              Service Number
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              value="vehicleNumber"
              checked={userIp.service.type === 'vehicleNumber'}
              onChange={(e) => {
                handleChange(e.target.value, 'servicetype');
              }}
            />
            <label className="form-check-label" htmlFor="flexRadioDefault2">
              Bus Number
            </label>
          </div>
        </div>
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => {
              handleChange(e.target.value, 'serviceVehicleNumber');
            }}
            required
            value={userIp.service.number}
            placeholder="Enter Service/Bus Number"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="FormControlInput2" className="form-label">
            Date of Journey
          </label>
          <input
            type="date"
            className="form-control"
            id="FormControlInput2"
            onChange={(e) => {
              handleChange(e.target.value, 'doj');
            }}
            required
            value={userIp.doj}
          />
        </div>

        <div>
          <label htmlFor="FormControlRateArea1" className="form-label">
            Rate our service
          </label>
          <div className="d-flex justify-content-between gap-3">
            {emotionData.map((d, i) => {
              return (
                <div
                  key={i + 10}
                  className={`${
                    userIp.rate === `${d.rateValue}`
                      ? 'feedback__option__selected'
                      : ''
                  } emotionDiv d-flex justify-content-end flex-column position-relative`}
                  style={{ minWidth: '55px', width: '23%', height: '90px' }}
                >
                  <img
                    src={d.imgSrc}
                    className="position-absolute"
                    style={{
                      top: '10px',
                      left: '50%',
                      transform: 'translateX(-18px)',
                    }}
                  />
                  <input
                    type="button"
                    className=""
                    id="FormControlInput3"
                    value={d.rateValue}
                    onClick={(e) => {
                      handleChange(e.target.value, 'rate');
                    }}
                    style={{
                      height: '70%',
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="FormControlTextarea1" className="form-label">
            Description
          </label>
          <textarea
            className="form-control"
            id="FormControlTextarea1"
            placeholder="Type here..."
            rows="2"
            onChange={(e) => {
              handleChange(e.target.value, 'desc');
            }}
            value={userIp.desc}
          ></textarea>
        </div>
        <div className="justify-content-center d-flex submitBtn">
          <button type="submit" className="w-100 btn">
            Submit to TSTRTC
          </button>
        </div>

        {submitted &&
          (submitted === 'success' ? (
            <div
              className="alert alert-success d-flex justify-content-between position-absolute top-0 w-100"
              role="alert"
            >
              <p className="mb-1">Your Feedback has been submitted.</p>
              <h5>
                <span>
                  <Link to="/">Dismiss </Link>
                </span>
              </h5>
            </div>
          ) : (
            <div
              className="alert alert-danger d-flex justify-content-between position-absolute top-0 w-100"
              role="alert"
            >
              <p className="mb-1">
                Something went wrong! Please contact admin.
              </p>
              <h5>
                <span>
                  <Link to="/">Dismiss </Link>
                </span>
              </h5>
            </div>
          ))}
      </form>
    </div>
  );
}
