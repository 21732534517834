import React from 'react';
import { MemoryRouter as Router, Route } from 'react-router-dom';

import { HomePage, AboutPage, NotificationsPage, SplashWebPage } from './pages';

import { Routes } from './routes';
import { NavHome } from './components';

// Importing Sass with Bootstrap CSS
import './sass/App.scss';

function App() {
  return (
    <Router>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/about" component={AboutPage} />
      <Route exact path="/notifications" component={NotificationsPage} />

      {Object.entries({ ...Routes['intra'], ...Routes['inter'] }).map(
        ([key, { path, Comp, pageTitle = null }]) => {
          return (
            <Route
              key={key}
              exact
              path={path}
              render={(props) => {
                return (
                  <div className="d-flex flex-column vh-100">
                    {pageTitle && <NavHome title={pageTitle} />}
                    <Comp {...props} />
                  </div>
                );
              }}
            />
          );
        }
      )}
    </Router>
  );
}

export default App;
