import React, { useEffect, useRef, useState } from 'react';

import bgBus from '../assets/bgBus.svg';
import smBus from '../assets/smbus.svg';
import { CommonSectionLRNav, TripInfoCard, SearchBar } from '../components';
import { CommonService } from '../services/api';
import useLocalStorage from '../hooks/useLocalStorage';
import busIconFront from './../assets/mapNavigation/busIconFront.svg';
import { BusStopItem } from './SearchNearestBusStopPage';

export function SearchByServiceNumberPage() {
  const [serviceListData, setServiceListData] = useState([]);
  const [selectedServiceData, setSelectedServiceData] = useState([]);
  const [shouldTripMapComOpen, setShouldTripMapComOpen] = useState(false);
  const submittedRef = useRef({
    value: '',
    submitted: false,
  });

  const [selectedOperationType, _] = useLocalStorage('operationType', '_');
  const [searchTerm, setSearchTerm] = useState('');

  const [selectedTripData, setSelectedTripData] = useState(null);

  const handleShouldTripMapComOpen = (event) => {
    setShouldTripMapComOpen(!shouldTripMapComOpen);
  };

  let operationType =
    selectedOperationType === 'inter'
      ? 1
      : selectedOperationType === 'intra'
      ? 2
      : 1;

  useEffect(() => {
    CommonService.getAllServices(operationType).then((result) => {
      if (result instanceof Array && result.length > 0) {
        setServiceListData(result);
      }
    });
  }, []);

  const handleServiceItemClick = (data = null) => {
    if (data.id) {
      CommonService.searchByServiceId(data.id, operationType).then((result) => {
        if (!submittedRef.current.submitted) {
          submittedRef.current.submitted = true; //just once for bg change
        }
        setSelectedServiceData(result);
      });
    }
  };

  const handleCardClick = (data = null) => {
    setSelectedTripData(data);
    handleShouldTripMapComOpen();
  };

  const handleInput = function (s) {
    setSearchTerm(s);
  };

  return (
    <div
      className="container-fluid flex-grow-1"
      style={{ padding: '0', margin: '0' }}
    >
      {shouldTripMapComOpen && (
        <CommonSectionLRNav
          navTitle={selectedTripData?.serviceName}
          handleShouldTripMapComOpen={handleCardClick}
          tripDetails={selectedTripData}
        />
      )}
      <div className="d-flex m-0" style={{ height: '100%' }}>
        <div
          className="search-left bg-white feedback__option  p-0"
          style={{ border: 'none' }}
        >
          <SearchBar
            placeholder="Search here..."
            required={false}
            goToHome={true}
            handleInput={handleInput}
          />

          <div
            className="total-bus-stops mb-0"
            style={{
              padding: '5px 16px',
              background: '#FAFAFA',
            }}
          >
            <strong>Service Number</strong>
            <strong>Service Type</strong>
          </div>
          <div
            className="left-side-section scroll-container "
            style={{
              padding: '10px 16px 24px 16px',
              height: 'calc(100vh - 90px)',
              backgroundColor: 'white',
            }}
          >
            <div className="bus-stops-container">
              {serviceListData.length > 0 ? (
                serviceListData
                  .filter((d) => d.name.includes(searchTerm))
                  .map((d) => {
                    return (
                      <BusStopItem
                        key={d.id}
                        title={d.name}
                        icon={busIconFront}
                        data={d}
                        serviceType={d.serviceType}
                        showDistance={false}
                        handleClick={() => handleServiceItemClick(d)}
                      />
                    );
                  })
              ) : (
                <p>No Vehicle Available</p>
              )}
            </div>
          </div>
        </div>

        <div
          className="feedback__content  pb-4"
          style={{
            background: submittedRef.current.submitted
              ? '#f4f6fa'
              : `#f4f6fa url(${bgBus}) no-repeat center`,
          }}
        >
          {submittedRef.current.submitted && (
            <div>
              <p className="w-60 mx-auto text-center mt-2">
                <b>{submittedRef.current.value}</b>
                <span
                  className="infoDetails__heading"
                  style={{ fontSize: '13px' }}
                >
                  <b>&nbsp;Service Number Result</b>
                </span>
              </p>
              {selectedServiceData.length > 0 ? (
                selectedServiceData.map((d, i) => {
                  return (
                    <TripInfoCard
                      icon={smBus}
                      handleShouldTripMapComOpen={handleShouldTripMapComOpen}
                      data={d}
                      key={i}
                      handleCardClick={handleCardClick}
                    />
                  );
                })
              ) : (
                <center>
                  <h4>No Trips Available</h4>
                </center>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
