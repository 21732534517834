import React, { useState, useEffect } from 'react';
import {
  NavHome,
  CurrentLocation,
  SearchBar,
  MmiMap,
  ErrorBoundary,
  Direction,
} from '../components';

import { CommonService } from '../services/api';
import { getGeoLocation } from '../common/utils';
import { mmiDataTypes } from '../common/types';

import refresh1Svg from './../assets/refresh-1.svg';
import nearestBusStopSvg from './../assets/bus-stop.svg';
import directionSvg from './../assets/direction-1.svg';
import locationMarker from './../assets/search/location_marker.svg';

export const BusStopItem = ({
  icon,
  title,
  distance,
  handleClick,
  data = {},
  type = null,
  handleClose = null,
  showDistance = true,
  serviceType = null,
}) => {
  const handleItemClick = function () {
    if (type === 'changeCurrentLocation' && handleClose && showDistance) {
      handleClick(data);
      handleClose();
    } else {
      handleClick(data);
    }
  };

  return (
    <div className="bus-stop-item cursorPointer" onClick={handleItemClick}>
      <div className="title">
        <span>
          <img src={icon} alt="direction" />
        </span>
        <span>{title}</span>
      </div>
      {showDistance ? (
        <div className="distance">
          <span>{String(parseInt(distance * 1000).toFixed(1)) + ' mtr'}</span>

          <span>
            <img src={directionSvg} alt="direction" />
          </span>
        </div>
      ) : (
        <span
          className="px-1 rounded-4 text-end greenMyElem"
          // style={{ width: '50%', wordBreak: 'break-word' }}
        >
          {serviceType}
        </span>
      )}
    </div>
  );
};

export const ChangeCurrentLocation = ({
  handleClose,
  handleClick,
  data = [],
  currentLocationAddress = null,
}) => {
  const [filteredData, setFilteredData] = useState([]);

  const handleFilter = function (s) {
    if (!s) {
      setFilteredData([]);
    } else {
      setFilteredData([
        ...data.filter((d) =>
          d.address.toLowerCase().includes(s.toLowerCase())
        ),
      ]);
    }
  };

  return (
    <div
      className="position-absolute top-0 start-0 w-100 min-vh-100"
      style={{ zIndex: 10, backgroundColor: 'white' }}
    >
      <SearchBar
        isRequired={true}
        placeholder="Search here..."
        handleClose={handleClose}
        handleInput={handleFilter}
      />
      <div
        className="left-side-section"
        style={{ maxHeight: 'calc(100vh - 80px)' }}
      >
        <CurrentLocation address={currentLocationAddress} />
        <div className="bus-stops-container">
          {filteredData.map((d) => {
            return (
              <BusStopItem
                key={d.id}
                title={d.address}
                distance={d.distance}
                icon={locationMarker}
                data={d}
                handleClick={handleClick}
                type="changeCurrentLocation"
                handleClose={handleClose}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const SearchNearestBusStopPage = () => {
  const [data, setData] = useState([]);
  const [isChangeLocationActive, setIsChangeLocationActive] = useState(false);
  const [currentLocationAddress, setCurrentLocationAddress] = useState({
    address: '',
    latlng: '',
  });

  const [showDirectionData, setShowDirectionData] = useState(null);

  // const location = useGeolocation();
  // console.log(location);

  const updateNearByStops = function (
    long = 77.2689539194107,
    lat = 28.550666179405972,
    maxDistance = 5,
    limit = 10
  ) {
    return CommonService.nearByStops(long, lat, maxDistance, limit).then(
      (res) => {
        setData(res);
      }
    );
  };

  useEffect(() => {
    getGeoLocation()
      .then((location) => {
        let { latitude, longitude } = location;

        CommonService.getLocationAddress(latitude, longitude).then((res) => {
          //console.log(res);
          setCurrentLocationAddress({
            address: res.results[0].formatted_address,
            latlng: `${res.results[0].lat},${res.results[0].lng}`,
          });

          updateNearByStops(res.results[0].lng, res.results[0].lat);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // useEffect(() => {
  //   const { latlng } = currentLocationAddress;
  //   let lat = latlng.split(',')[0];
  //   let long = latlng.split(',')[1];
  //   console.log(lat, long);
  //   let loading = true;
  //   if (!loading) return;
  //   updateNearByStops(long, lat);
  // }, []);

  const handleCurrentLocationChange = function (data) {
    setCurrentLocationAddress(data);
  };
  const handleBusStopClick = function (d) {
    setShowDirectionData(d);
  };

  return (
    <div className="tsrtc-container position-relative">
      {window.MapmyIndia &&
        window.MapmyIndia.direction &&
        showDirectionData && (
          <Direction
            handleClose={() => setShowDirectionData(null)}
            data={showDirectionData}
            currentLocationAddress={currentLocationAddress}
          />
        )}

      <div className="search-section variant-no-nav ">
        <div className="search-left position-relative">
          {isChangeLocationActive && (
            <ChangeCurrentLocation
              handleClose={() =>
                setIsChangeLocationActive(!isChangeLocationActive)
              }
              data={data}
              handleClick={handleCurrentLocationChange}
              currentLocationAddress={
                currentLocationAddress.address
                  ? currentLocationAddress.address
                  : 'Address not available!'
              }
            />
          )}

          <NavHome
            title="Nearest Bus Stops"
            icon={refresh1Svg}
            handler={() => updateNearByStops()}
            css={{ hs: { padding: '0 16px' }, asBtn: { marginRight: '0px' } }}
          />
          <div className="left-side-section scroll-container ">
            <div className="total-bus-stops">
              <strong>Nearest Bus Stops</strong>
              <span className="total-bus-stops-counts">
                <img src={nearestBusStopSvg} alt="bus stop" />
                {data && data.length > 0
                  ? `${data.length > 9 ? data.length : '0' + data.length}`
                  : '0'}
              </span>
            </div>

            <CurrentLocation
              changeable
              handler={() => setIsChangeLocationActive(!isChangeLocationActive)}
              address={
                currentLocationAddress?.address || 'Address not available!'
              }
            />

            <div className="bus-stops-container">
              {data.map((d) => {
                return (
                  <BusStopItem
                    key={d.id}
                    title={d.address}
                    distance={d.distance}
                    icon={nearestBusStopSvg}
                    data={d}
                    handleClick={handleBusStopClick}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="right-section">
          <ErrorBoundary>
            {!showDirectionData && (
              <MmiMap
                data={{ mmiDataType: mmiDataTypes.nearestBusStop, value: data }}
                currentLatLong={
                  currentLocationAddress.latlng
                    ? [...currentLocationAddress.latlng.split(',')]
                    : null
                }
                showCurrentLocationOnly={isChangeLocationActive}
              />
            )}
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};
