import React from 'react';

export const SelectCard = ({
  title = '',
  subTitle = '',
  subTitleLength = 10,
  handler = () => {},

  ...props
}) => {
  return (
    <div className="input-box cursorPointer" onClick={handler}>
      <label>{title}</label>
      <p title={subTitle}>
        {subTitle
          ? subTitle.length > subTitleLength
            ? subTitle.substring(0, subTitleLength) + '...'
            : subTitle
          : ''}
      </p>
    </div>
  );
};
