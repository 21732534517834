import React, { useEffect, useRef } from 'react';
import {
  epochDatetoLocale,
  addMultipleLayersOnMap,
  addMarkerOnMap,
  addPolygonOnMap,
} from './../common/utils';
import { mmiDataTypes } from '../common/types';

import ic_marker_focusedSVg from './../assets/ic_marker_focused.svg';
import busStop1Svg from './../assets/bus-stop-1.svg';

import ic_from from './../assets/mapNavigation/ic_from.png';
import ic_to from './../assets/mapNavigation/ic_to.png';

/**
 *
 * @param {Object} data {mmiDataType:'',value:{} || []}
 * @param {Array} currentLatLong [lat,lng]
 * @param {Boolean} showCurrentLocationOnly false
 * @returns
 */
export const MmiMap = function ({
  data = {},
  currentLatLong = null,
  showCurrentLocationOnly = false,
}) {
  const mapContainer = useRef(null);

  useEffect(() => {
    if (!window.mmiState.isInitialised) {
      window.initMmiMap();
    }

    window.mmiState.mmiMapEl.style.display = '';
    window.moveMmiMapTo(mapContainer.current);
    ////////
    let direction_plugin = null;

    // if there is data and showCurrentLocationOnly is false
    if (data && Object.keys(data).length > 0 && !showCurrentLocationOnly) {
      let { mmiDataType, value } = data;
      if (!value) return;

      if (mmiDataType === mmiDataTypes.direction) {
        let direction_option = {
          map: window.mmiState.mmiMap,
          start: value[0],
          end: value[1],
          callback: function (data) {
            console.log(data);
          },
        };

        if (window.MapmyIndia && window.MapmyIndia.direction) {
          direction_plugin = window.MapmyIndia.direction(direction_option);
        }
      }

      if (mmiDataType === mmiDataTypes.nearestBusStop) {
        addMultipleLayersOnMap(value, mmiDataType);
      }
      if (mmiDataType === mmiDataTypes.fromTo) {
        let { fromToLocation, stopsLocation, polyline, currentStopLocation } =
          value;

        // add from Marker
        if (fromToLocation.from.from.geometry.type === 'Point') {
          let { geometry, name, entryTime, eta } = fromToLocation.from.from;
          let fromLatlng = geometry.coordinates.slice().reverse();
          addMarkerOnMap(
            fromLatlng,
            {
              icon: window.L.icon({
                iconUrl: ic_from,
                iconSize: [30, 30],
              }),
            },
            `<span>Name: ${name || ''}</span><br><span> ${
              entryTime
                ? `Entry Time : ${epochDatetoLocale(entryTime)}`
                : eta
                ? `Estimated Time: ${epochDatetoLocale(eta)}`
                : ''
            }</span>`
          );
        } else if (fromToLocation.from.from.geometry.type === 'Polygon') {
          let polygonPoints =
            fromToLocation.from.from.geometry.coordinates[0].map((d) =>
              d.slice().reverse()
            );
          addPolygonOnMap(polygonPoints, {
            color: '#19bc9d',
          });
        }
        // add to Marker
        if (fromToLocation.to.to.geometry.type === 'Point') {
          let { geometry, name, entryTime, eta } = fromToLocation.to.to;
          let toLatlng = geometry.coordinates.slice().reverse();
          addMarkerOnMap(
            toLatlng,
            {
              icon: window.L.icon({
                iconUrl: ic_to,
                iconSize: [30, 30],
              }),
            },
            `<span>Name: ${name || ''}</span><br><span> ${
              entryTime
                ? `Entry Time : ${epochDatetoLocale(entryTime)}`
                : eta
                ? `Estimated Time: ${epochDatetoLocale(eta)}`
                : ''
            }</span>`
          );
        } else if (fromToLocation.to.to.geometry.type === 'Polygon') {
          let polygonPoints = fromToLocation.to.to.geometry.coordinates[0].map(
            (d) => d.slice().reverse()
          );
          addPolygonOnMap(polygonPoints, {
            color: '#19bc9d',
          });
        }

        // add stops
        addMultipleLayersOnMap(stopsLocation, mmiDataType);
        // add polyline
        Object.keys(polyline).forEach((k) => {
          let polylineData = polyline[k];
          let line = window.L.polyline(
            polylineData.polyline,
            polylineData.linecss
          ).addTo(window.mmiState.mmiLayerGroup);
        });
        // add current stop marker
        addMarkerOnMap(currentStopLocation, {
          icon: window.L.icon({
            iconUrl: busStop1Svg,
            iconSize: [25, 25],
          }),
        });
      }
    }

    if (currentLatLong) {
      let latlng = currentLatLong.map((d) => Number(d));
      let currLocationMk = addMarkerOnMap(
        [...latlng],
        {
          icon: window.L.icon({
            iconUrl: ic_marker_focusedSVg,
            iconSize: [25, 25],
          }),
        },
        `<p>You are here</P>`
      );
      currLocationMk.openPopup();
    }

    if (Object.keys(window.mmiState.mmiLayerGroup.getBounds()).length > 0) {
      window.mmiState.mmiMap.fitBounds(
        window.mmiState.mmiLayerGroup.getBounds(),
        { padding: [20, 20] }
      );
    }

    ////////
    return () => {
      window.mmiState.mmiLayerGroup.clearLayers();

      window.mmiState.mmiMapEl.style.display = 'none';
      window.moveMmiMapTo(document.body);

      if (direction_plugin) {
        direction_plugin.remove();
      }
    };
  }, [data, currentLatLong]);

  return (
    <div className="mapContainer">
      <div ref={mapContainer}></div>
    </div>
  );
};
