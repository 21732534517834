import React from 'react';

import { timeSecToHr, epochDatetoLocale } from './../common/utils';

import './../sass/SearchResult.scss';
import busSvg from '../assets//searchResult/bus.svg';
import useLocalStorage from '../hooks/useLocalStorage';

export const SearchResult = ({ data, handleClick }) => {
  const [selectedOperationType, _] = useLocalStorage('operationType', '_');

  return (
    <div className="right-section" style={{ flex: 1 }}>
      <section className="ticket-section scroll-container">
        <div className="ticket-heading">
          <h2>
            {' '}
            <span>{data.length} </span>Results Found{' '}
          </h2>
        </div>
        <div className="ticket-wraper cursorPointer">
          {data.map(
            (
              {
                from,
                to,
                totalStops,
                serviceType,
                serviceId,
                id,
                serviceName = '',
                vehicleId,
                vehicleNumber,
                duration,
                myPoint,
              },
              i
            ) => {
              return (
                <div
                  className="ticket-card"
                  key={id + '' + vehicleId}
                  onClick={() => handleClick(data[i])}
                >
                  <div className="from-to">
                    <div className="from-section">
                      <p>From</p>
                    </div>
                    <div className="distance-time">
                      <p>
                        <img src={busSvg} alt="" />
                        {timeSecToHr(duration)}
                      </p>
                    </div>
                    <div className="to-section">
                      <p>To</p>
                    </div>
                  </div>
                  <div className="place-name">
                    <h4>{from.name}</h4>
                    <h4>{to.name}</h4>
                  </div>
                  <div className="info-section">
                    <div>
                      <h5>Bus Number</h5>
                      <p>{vehicleNumber}</p>
                    </div>
                    <div>
                      <h5>Service</h5>
                      <p>{serviceName}</p>
                    </div>
                    <div>
                      <h5>
                        {myPoint.exitTime || myPoint.entryTime ? (
                          <span>&nbsp;</span>
                        ) : (
                          'ETA'
                        )}
                      </h5>
                      <p>
                        {myPoint.exitTime ? (
                          <span style={{ color: 'red' }}>Bus Left</span>
                        ) : myPoint.entryTime ? (
                          'Bus Reached'
                        ) : myPoint.eta ? (
                          epochDatetoLocale(myPoint.eta)
                        ) : (
                          ''
                        )}
                      </p>
                    </div>
                  </div>

                  {selectedOperationType != 'intra' ? (
                    <>
                      <div className="devider-line"></div>
                      <div className="info-section">
                        <div>
                          <h5>Departure</h5>
                          <p>{epochDatetoLocale(from.plannedTime)}</p>
                        </div>
                        <div>
                          <h5>
                            Stops{' '}
                            <span>
                              {totalStops > 9 ? totalStops : '0' + totalStops}
                            </span>
                          </h5>
                        </div>
                        <div>
                          <h5>Arrival</h5>
                          <p>{epochDatetoLocale(to.plannedTime)}</p>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              );
            }
          )}
        </div>
      </section>
    </div>
  );
};
