import React, { useEffect, useState } from 'react';
import { CommonService } from '../services/api';
import { epochDatetoLocale, timeMinToHr, timeSecToHr } from '../common/utils';
import useLocalStorage from '../hooks/useLocalStorage';

export function TripInfoCard({
  icon,
  data,
  // handleShouldTripMapComOpen,
  handleCardClick,
  ...props
}) {
  const [selectedOperationType, _] = useLocalStorage('operationType', '_');

  return (
    <div
      className="w-60 rounded-4 bg-white p-4 mx-auto mt-4 shadow-sm"
      role="button"
      onClick={() => {
        handleCardClick(data);
      }}
      style={{ position: 'relative' }}
    >
      {selectedOperationType != 'intra' ? (
        <>
          <div
            style={{
              height: '0px',
              width: '0px',
              backroundColor: 'transparent',
              borderLeft: '10px solid #f5f5f5',
              borderRight: '10px solid transparent',
              borderTop: '10px solid transparent',
              borderBottom: '10px solid transparent',
              position: 'absolute',
              top: '56%',
              left: '0%',
              borderRadius: '4px',
            }}
          ></div>
          <div
            style={{
              height: '0px',
              width: '0px',
              backroundColor: 'transparent',
              borderLeft: '10px solid transparent',
              borderRight: '10px solid #f5f5f5',
              borderTop: '10px solid transparent',
              borderBottom: '10px solid transparent',
              position: 'absolute',
              top: '56%',
              right: '0%',
              borderRadius: '4px',
            }}
          ></div>
        </>
      ) : null}

      <div className="d-flex">
        <span className="infoDetails__heading align-self-center">From</span>
        <span className=" d-flex flex-grow-1">
          <span className="flex-grow-1 align-self-center px-1">
            <hr />
          </span>
          <span
            className="align-self-center myPrimary"
            style={{ fontSize: '12px' }}
          >
            <img src={icon} alt="icon" />
            {data.duration && timeSecToHr(data.duration)}
          </span>
          <span className="flex-grow-1 align-self-center px-1">
            <hr />
          </span>
        </span>
        <span className="infoDetails__heading align-self-center">To</span>
      </div>
      <div className="d-flex justify-content-between">
        <span className="blueMyEle">{data.from.name}</span>
        <span className="blueMyEle">{data.to.name}</span>
      </div>
      <div className="d-flex justify-content-between">
        <InfoDetails title="Bus Number" value={data.vehicleNumber} />
        <InfoDetails
          title={selectedOperationType == 'intra' ? 'Service Name' : 'Service'}
          value={data.serviceName ? data.serviceName : ''}
        />
        <InfoDetails
          title={data?.myPoint.exitTime || data?.myPoint.entryTime ? '' : 'ETA'}
          value={
            data?.myPoint.exitTime
              ? 'Bus Left'
              : data.myPoint.entryTime
              ? 'Bus Reached'
              : data.myPoint.eta
              ? epochDatetoLocale(data.myPoint?.eta, '24')
              : ''
          }
          text="text-end"
        />
      </div>

      {selectedOperationType != 'intra' ? (
        <>
          <hr className="dashedHr" />
          <div className="d-flex justify-content-between">
            <InfoDetails
              title="Departure"
              value={epochDatetoLocale(data.from.plannedTime)}
            />
            <div className="infoDetails align-self-center">
              <span className="infoDetails__heading">Stops</span>
              <span
                style={{
                  marginLeft: '7px',
                  fontWeight: '500',
                  color: '#555555',
                  fontSize: '13px',
                }}
              >
                {data.totalStops}
              </span>
            </div>
            <InfoDetails
              title="Arrival"
              value={epochDatetoLocale(data.to.plannedTime)}
              text="text-end"
            />
          </div>
        </>
      ) : null}
    </div>
  );
}

const InfoDetails = ({
  title = 'No Title',
  value = 'No Value',
  text = null,
}) => {
  return (
    <div className="infoDetails">
      <p className={`${text ? text : ''} infoDetails__heading`}>
        {title ? title : <span>&nbsp;</span>}
      </p>
      <p
        className={`${text ? text : ''}`}
        style={{ fontWeight: '500', color: '#555555', fontSize: '13px' }}
      >
        {value ? (
          value === 'Bus Left' ? (
            <span style={{ color: 'red' }}>{value}</span>
          ) : (
            value
          )
        ) : (
          ''
        )}
      </p>
    </div>
  );
};
