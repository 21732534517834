import React from 'react';
import { Link } from 'react-router-dom';

import backSvg from './../assets/back.svg';

import './../sass/Header.scss';

export const NavHome = ({
  title = 'Home',
  goToHome = true,
  handleNonHomeBtn = null,
  ...props
}) => {
  // height 52px
  return (
    <header
      className="header-section"
      style={props.css?.hs && { ...props.css.hs }}
    >
      <div className="top-header">
        <div className="page-title">
          <div className="back-bt">
            {goToHome ? (
              <Link to="/">
                <button>
                  <img src={backSvg} alt="back" />
                </button>
              </Link>
            ) : (
              <button>
                <img src={backSvg} alt="back" onClick={handleNonHomeBtn} />
              </button>
            )}
          </div>
          <h1>{title}</h1>
        </div>
        <div className="action-section">
          <button style={props.css?.asBtn && { ...props.css.asBtn }}>
            {props.icon && (
              <img
                src={props.icon}
                alt="icon"
                className="ms-auto cursorPointer"
                onClick={props.handler}
              />
            )}
          </button>
        </div>
      </div>
    </header>
  );
};
