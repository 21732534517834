import React, { useState } from 'react';
import { NavHome } from '../components';
import Offcanvas from 'react-bootstrap/Offcanvas';

import TSRTC_LOGO from '../assets/TSRTC_LOGO.png';
import CM_image from '../assets/info/CMImage.png';
import call1 from '../assets/info/call-1.svg';
import call from '../assets/info/call.svg';
import send from '../assets/info/send.svg';
import mailIcon from '../assets/info/mailicon.svg';

import Brand from '../assets/mmibranding.png';
import stateMap from '../assets/info/mapgraphic.svg';

export const AboutPage = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="container-fluid px-0">
      <NavHome title="About TSRTC" />

      <div style={{ backgroundColor: 'whitesmoke', height: '100%' }}>
        <div
          style={{
            height: '4rem',
            width: '4rem',
            borderRadius: '2rem',
            position: 'fixed',
            bottom: '2.2rem',
            right: '2.2rem',
            backgroundColor: '#19bc9c',
            border: '0.3rem solid #fff',
            cursor: 'pointer',
          }}
          // onClick={handleShow}
        >
          <div style={{ marginTop: '0.9rem', marginLeft: '0.9rem' }}>
            <a href="tel:040-69440000">
              <img src={call} alt="call" />
            </a>
          </div>
        </div>
        <div className="d-flex  p-3">
          <div
            className="about-block-1"
            style={{
              backgroundColor: '#fff',
              color: 'black',
              width: '90%',
              borderRadius: '6.5px 0px 0px 6.5px',
            }}
          >
            <div className="p-3">
              <img
                src={TSRTC_LOGO}
                style={{ height: '70px', width: '70px' }}
                alt="logo"
              ></img>
            </div>
            <h5
              style={{
                fontWeight: 'bolder',
                color: '#19bc9c',
                marginLeft: '20px',
              }}
            >
              TSRTC
            </h5>
            <h6 style={{ fontWeight: 'bold', marginLeft: '1rem' }}>
              Live Bus Tracking
            </h6>
            <span style={{ fontSize: '12px', marginLeft: '1rem' }}>
              V-2.1.6
            </span>

            <div style={{ marginLeft: '1rem' }}>
              <img
                alt="MApMyIndia"
                src={Brand}
                style={{
                  height: '2rem',
                  width: '10rem',
                  margin: '2rem 0 1rem 0',
                }}
              ></img>
            </div>
          </div>
          <div
            className="about-block-2"
            style={{
              backgroundColor: '#19bc9c',
              borderRadius: '0px 6.5px 6.5px 0px',
            }}
          >
            <div style={{ marginLeft: '-1rem', marginTop: '0.5rem' }}>
              <img
                src={stateMap}
                style={{ marginLeft: '-2.4rem', marginTop: '1.4rem' }}
                alt="stateMap"
              ></img>
            </div>
          </div>
        </div>
        <div className="d-flex p-3">
          <div
            className="d-flex"
            style={{
              backgroundColor: '#fff',
              color: 'black',
              width: '100%',
              borderRadius: '6.5px',
            }}
          >
            <div className="p-2">
              <h6
                style={{
                  fontWeight: '600',
                  marginLeft: '1rem',
                  color: '#000066',
                }}
              >
                For e-TIcketing/Refunds Queries
              </h6>
              <div className="d-flex" style={{ marginLeft: '1rem' }}>
                <img src={mailIcon} alt="mail"></img>
                <span style={{ marginLeft: '1rem' }}>
                  Email: online.support@tsrtconline.com
                </span>
              </div>
            </div>
            <div className="p-2">
              <h6
                style={{
                  fontWeight: '600',
                  marginLeft: '1rem',
                  color: '#000066',
                }}
              >
                For Other Queries
              </h6>
              <div className="d-flex" style={{ marginLeft: '1rem' }}>
                <img src={mailIcon} alt="mail"></img>
                <span style={{ marginLeft: '1rem' }}>
                  Email: customercare@tsrtconline.com
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="p-3">
          <div
            className="p-2"
            style={{
              backgroundColor: '#fff',
              color: 'black',
              borderRadius: '6.5px',
            }}
          >
            <h6
              style={{
                fontWeight: '600',
                marginLeft: '1rem',
                color: '#000066',
              }}
            >
              Vision of TSRTC
            </h6>
            <div
              style={{
                display: 'webkit-box',
                WebkitLineClamp: '3',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginLeft: '1rem',
                textIndent: '0',
              }}
            >
              TSRTC is committed to provide consistently high quality of
              services and to continuously improve the services through a
              process of teamwork for the utmost satisfaction of the passengers
              and to attain a position of pre-eminence in the Bus Transport
              sector.
            </div>
          </div>
        </div>
        <div className="p-3">
          <div
            className="p-2"
            style={{
              backgroundColor: '#fff',
              color: 'black',
              borderRadius: '6.5px',
            }}
          >
            <h6
              style={{
                fontWeight: '600',
                marginLeft: '1rem',
                color: '#000066',
              }}
            >
              Corporate Philosophy
            </h6>
            <div
              style={{
                display: 'webkit-box',
                WebkitLineClamp: '3',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginLeft: '1rem',
                textIndent: '0',
              }}
            >
              <ul>
                <li>
                  To provide safe, clean, comfortable, punctual and courteous
                  commuter service at an economic fare.
                </li>
                <li>
                  To provide employee satisfaction in financial and humanistic
                  terms.
                </li>
                <li>
                  To strive towards financial self-reliance in regard to
                  performance and growth.
                </li>
                <li>
                  To attain a position of reputation and respect in the society.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="p-3">
          <div
            className="p-2"
            style={{
              backgroundColor: '#fff',
              color: 'black',
              borderRadius: '6.5px',
            }}
          >
            <h6
              style={{
                fontWeight: '600',
                marginLeft: '1rem',
                color: '#000066',
              }}
            >
              Our Websites
            </h6>
            <div
              style={{
                display: 'webkit-box',
                WebkitLineClamp: '3',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginLeft: '1rem',
                textIndent: '0',
              }}
            >
              <div className="d-flex">
                <div
                  style={{
                    height: '10px',
                    width: '10px',
                    borderRadius: '15px',
                    backgroundColor: '#19bc9c',
                    marginTop: '7px',
                  }}
                ></div>
                <div style={{ marginLeft: '1rem' }}>
                  {' '}
                  For Seat Reservations:{' '}
                  <a
                    href="https://www.tsrtconline.in"
                    rel="noreferrer"
                    target="_blank"
                  >
                    www.tsrtconline.in
                  </a>
                </div>
              </div>
              <div className="d-flex">
                <div
                  style={{
                    height: '10px',
                    width: '10px',
                    borderRadius: '15px',
                    backgroundColor: '#19bc9c',
                    marginTop: '7px',
                  }}
                ></div>
                <div style={{ marginLeft: '1rem' }}>
                  For Cargo & Parcel:{' '}
                  <a
                    href="https://www.tsrtcparcel.in"
                    rel="noreferrer"
                    target="_blank"
                  >
                    www.tsrtcparcel.in
                  </a>
                </div>
              </div>

              <div className="d-flex">
                <div
                  style={{
                    height: '10px',
                    width: '10px',
                    borderRadius: '15px',
                    backgroundColor: '#19bc9c',
                    marginTop: '7px',
                  }}
                ></div>
                <div style={{ marginLeft: '1rem' }}>
                  For Bus pass:{' '}
                  <a
                    href="https://www.online.tsrtcpass.in"
                    rel="noreferrer"
                    target="_blank"
                  >
                    www.online.tsrtcpass.in
                  </a>
                </div>
              </div>
              <div className="d-flex">
                <div
                  style={{
                    height: '10px',
                    width: '10px',
                    borderRadius: '15px',
                    backgroundColor: '#19bc9c',
                    marginTop: '7px',
                  }}
                ></div>
                <div style={{ marginLeft: '1rem' }}>
                  Official website:{' '}
                  <a
                    href="https://www.tsrtc.telangana.gov.in"
                    rel="noreferrer"
                    target="_blank"
                  >
                    www.tsrtc.telangana.gov.in
                  </a>
                </div>
              </div>
              <div className="d-flex">
                <div
                  style={{
                    height: '10px',
                    width: '10px',
                    borderRadius: '15px',
                    backgroundColor: '#19bc9c',
                    marginTop: '7px',
                  }}
                ></div>
                <div style={{ marginLeft: '1rem' }}>
                  For tracking:{' '}
                  <a
                    href="https://www.tsrtcbustracking.in"
                    rel="noreferrer"
                    target="_blank"
                  >
                    www.tsrtcbustracking.in
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-3">
          <div
            className="p-2"
            style={{
              backgroundColor: '#fff',
              color: 'black',
              borderRadius: '6.5px',
            }}
          >
            <h6
              style={{
                fontWeight: '600',
                marginLeft: '1rem',
                color: '#000066',
              }}
            >
              Call Center Numbers
            </h6>
            <div
              style={{
                display: 'webkit-box',
                WebkitLineClamp: '3',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginLeft: '1rem',
                textIndent: '0',
              }}
            >
              <div className="d-flex">
                <div
                  style={{
                    height: '10px',
                    width: '10px',
                    borderRadius: '15px',
                    backgroundColor: '#19bc9c',
                    marginTop: '7px',
                  }}
                ></div>
                <div style={{ marginLeft: '1rem' }}> 040-23450033</div>
              </div>
              <div className="d-flex">
                <div
                  style={{
                    height: '10px',
                    width: '10px',
                    borderRadius: '15px',
                    backgroundColor: '#19bc9c',
                    marginTop: '7px',
                  }}
                ></div>
                <div style={{ marginLeft: '1rem' }}>040-69440000</div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Offcanvas
            show={show}
            onHide={handleClose}
            placement="bottom"
            style={{ borderRadius: '10px 10px 0px 0px', height: 'max-content' }}
          >
            <hr
              style={{
                width: '5%',
                height: '0.2rem',
                borderRadius: '6.5px',
                marginLeft: '47.5%',
              }}
            ></hr>
            <Offcanvas.Header closeButton className="infoClosingBtn">
              <Offcanvas.Title>Helpline Numbers</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="d-flex" style={{ position: 'relative' }}>
                <div>Call Bus Station (RTC)</div>
                <div
                  style={{
                    position: 'absolute',
                    right: '2%',
                    cursor: 'pointer',
                  }}
                >
                  <img src={call1} alt="call"></img>
                </div>
              </div>
              <hr style={{ backgroundColor: '#bababf' }}></hr>
              <div className="d-flex" style={{ position: 'relative' }}>
                <div>Call Central Complaint Cell (RTC)</div>
                <div
                  style={{
                    position: 'absolute',
                    right: '2%',
                    cursor: 'pointer',
                  }}
                >
                  <img src={call1} alt="call"></img>
                </div>
              </div>
              <hr style={{ backgroundColor: '#bababf' }}></hr>
              <div className="d-flex" style={{ position: 'relative' }}>
                <div>Send SMS Central Complaint Cell (RTC)</div>
                <div
                  style={{
                    position: 'absolute',
                    right: '2%',
                    cursor: 'pointer',
                  }}
                >
                  <img src={send} alt="send"></img>
                </div>
              </div>
              <hr style={{ backgroundColor: '#bababf' }}></hr>
              <div className="d-flex" style={{ position: 'relative' }}>
                <div>Call DM (RTC)</div>
                <div
                  style={{
                    position: 'absolute',
                    right: '2%',
                    cursor: 'pointer',
                  }}
                >
                  <img src={call1} alt="call"></img>
                </div>
              </div>
              <hr style={{ backgroundColor: '#bababf' }}></hr>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>
    </div>
  );
};
