import React, { useEffect, useState } from 'react';
import {
  emergencyFeedbackData,
  contactData,
} from '../staticData/emergencyData';
import { Link } from 'react-router-dom';
import { CommonService } from '../services/api';

export function EmergencyPage() {
  const [option, setOption] = useState('1');

  const handleOptionSet = (type) => {
    if (type === option) {
      return;
    }

    setOption(type);
  };
  // flex-grow-1
  return (
    <div
      className="container-fluid "
      style={{ padding: '0', margin: '0', height: '100vh' }}
    >
      <div
        className="d-flex m-0 mw-100"
        style={{ height: '100%', marginLeft: '0' }}
      >
        <div className="feedback__option  d-flex flex-column justify-content-between">
          <div>
            {emergencyFeedbackData.map((d, i) => {
              return (
                <div
                  key={i}
                  className={`${
                    option == d.optionValue ? 'feedback__option__selected' : ''
                  } card`}
                >
                  <div
                    className="card-body cursorPointer"
                    onClick={() => handleOptionSet(d.optionValue)}
                  >
                    <div className="rounded-3 ">
                      <img src={d.imgSrc} alt="" />
                    </div>
                    <p className="card-text align-self-center">{d.title}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            <center>
              <p>Call Central Complaint Cell (TSRTC)</p>
            </center>

            <div className="contactDiv d-flex justify-content-between">
              {contactData.map((d, i) => {
                return (
                  <div
                    key={(i + 1) * 100}
                    className="rounded-4 position-relative py-3"
                  >
                    <div
                      className="position-absolute"
                      style={{ top: '-15px', left: '30%' }}
                    >
                      <a href={d.phNum}>
                        <img
                          className="rounded-circle p-1"
                          src={d.imgSrc}
                          alt={d.value}
                        />
                      </a>
                    </div>
                    <center>{d.title}</center>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="feedback__content">
          {option === '1' && <EmergencyFeedbackForm option="1" />}
          {option === '2' && <EmergencyFeedbackForm option="2" />}
          {option === '3' && <EmergencyFeedbackForm option="3" />}
          {option === '4' && <EmergencyFeedbackForm option="4" />}
        </div>
      </div>
    </div>
  );
}

function EmergencyFeedbackForm({ option }) {
  const [userIp, setUserIp] = useState({
    number: '',
    desc: '',

    service: {
      type: 'serviceNumber',
      number: '',
    },
    rate: null,
    type: option,
  });
  const [submitted, setSubmitted] = useState(null);

  const handleChange = (value, type) => {
    // console.log(type, value, userIp);

    if (type == 'number') {
      setUserIp({ ...userIp, number: value });
    } else if (type == 'servicetype') {
      setUserIp({
        ...userIp,
        service: { ...userIp.service, type: value },
      });
    } else if (type == 'serviceVehicleNumber') {
      setUserIp({
        ...userIp,
        service: { ...userIp.service, number: value },
      });
    } else if (type == 'desc') {
      setUserIp({
        ...userIp,
        desc: value,
      });
    } else if (type == 'rate') {
      setUserIp({
        ...userIp,
        rate: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //      console.log('timestamp - ', timestamp, ...userIp.doj.split('-'));
    let dataTobeSubmitted = {
      type: Number(userIp.type),
      mobile: userIp.number,
      [userIp.service.type]: userIp.service.number,
      description: userIp.desc,
      timestamp: '',
    };
    //console.log('dataTobeSubmitted - ', dataTobeSubmitted);

    CommonService.savesos(dataTobeSubmitted)
      .then((result) => {
        setSubmitted('success');
        setUserIp({
          number: '',
          desc: '',

          service: {
            type: 'serviceNumber',
            number: '',
          },

          type: option,
        });
      })
      .catch((err) => {
        setSubmitted('failed');
        alert(err);
      });
  };

  useEffect(() => {
    let timerId = setTimeout(() => setSubmitted(null), 4000);

    return () => {
      clearTimeout(timerId);
    };
  }, [submitted]);

  return (
    <div className="w-70 pt-30 m-auto py-3 position-relative">
      <div className="sosDiv mb-3 d-flex justify-content-between px-4 py-3 rounded-4 one-edge-shadow-bottom">
        <div>
          <p className="mb-1">
            <b>Are you in Emergency?</b>
          </p>
          <p className="m-0 w-60">
            Press <strong style={{ color: '#df0d17b3' }}>SOS</strong> button and
            help will reach you soon
          </p>
        </div>
        <div className="align-self-center">
          <button className="px-5" style={{ cursor: 'default' }}>
            SOS
          </button>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="FormControlInput1" className="form-label">
            Mobile Number
          </label>
          <input
            type="number"
            maxLength="10"
            className="form-control"
            id="FormControlInput1"
            placeholder="Enter Mobile Number"
            value={userIp.number}
            onChange={(e) => {
              handleChange(e.target.value, 'number');
            }}
          />
        </div>
        <div className="d-flex">
          <div className="form-check me-3">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              value="serviceNumber"
              checked={userIp.service.type === 'serviceNumber'}
              onChange={(e) => {
                handleChange(e.target.value, 'servicetype');
              }}
            />
            <label className="form-check-label" htmlFor="flexRadioDefault1">
              Service Number
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              value="vehicleNumber"
              checked={userIp.service.type === 'vehicleNumber'}
              onChange={(e) => {
                handleChange(e.target.value, 'servicetype');
              }}
            />
            <label className="form-check-label" htmlFor="flexRadioDefault2">
              Bus Number
            </label>
          </div>
        </div>
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => {
              handleChange(e.target.value, 'serviceVehicleNumber');
            }}
            placeholder="Enter Service/Bus Number"
            required
            value={userIp.service.number}
          />
        </div>

        {option === '3' && (
          <div className="mb-3">
            <label htmlFor="FormControlTextarea1" className="form-label">
              Description
            </label>
            <textarea
              className="form-control"
              id="FormControlTextarea1"
              placeholder="Type here..."
              rows="2"
              onChange={(e) => {
                handleChange(e.target.value, 'desc');
              }}
              value={userIp.desc}
            ></textarea>
          </div>
        )}
        <div className="justify-content-center d-flex submitBtn">
          <button type="submit" className="w-100 btn">
            Submit to TSTRTC
          </button>
        </div>

        {submitted &&
          (submitted === 'success' ? (
            <div
              className="alert alert-success d-flex justify-content-between position-absolute top-0 w-100"
              role="alert"
            >
              <p className="mb-1">Your Feedback has been submitted.</p>
              <h5>
                <span>
                  <Link to="/">Dismiss </Link>
                </span>
              </h5>
            </div>
          ) : (
            <div
              className="alert alert-danger d-flex justify-content-between position-absolute top-0 w-100"
              role="alert"
            >
              <p className="mb-1">
                Something went wrong! Please contact admin.
              </p>
              <h5>
                <span>
                  <Link to="/">Dismiss </Link>
                </span>
              </h5>
            </div>
          ))}
      </form>
    </div>
  );
}
