import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './../sass/Header.scss';
// import { debouncedFn } from '../common/utils';

import clear from './../assets/clear.svg';

export const SearchBar = ({
  goToHome = false,
  handleSearchBarClick = null,
  forBusRouteSearch = false,
  ...props
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = function (e) {
    setSearchTerm(e.target.value);
    props.handleInput(e.target.value);
  };
  const handleClear = () => {
    setSearchTerm('');
    props.handleInput('');
  };

  const handleSearchClick = function () {
    if (handleSearchBarClick == null) return;

    handleSearchBarClick();
  };
  // height 74px
  return (
    <header
      className="header-section"
      style={{
        padding: goToHome ? '0 14px' : '',
        background: forBusRouteSearch ? '#ffff' : '#19bc9d',
      }}
    >
      <div className="top-header" style={{ padding: goToHome ? '8px 0' : '' }}>
        <div
          className="search-box position-relative align-items-center"
          style={{ border: forBusRouteSearch ? '1px solid #F5F5F5' : '' }}
        >
          {props.handleClose && (
            <button onClick={props.handleClose}>
              <span className="material-symbols-outlined">arrow_back </span>
            </button>
          )}
          {goToHome && (
            <Link to="/">
              <button>
                <span className="material-symbols-outlined">arrow_back </span>
              </button>
            </Link>
          )}

          <input
            type="text"
            placeholder={props.placeholder}
            value={searchTerm}
            onChange={handleChange}
            required={props.isRequired}
            style={{ padding: goToHome ? '10px 0' : '' }}
            onClick={handleSearchClick}
          />
          {searchTerm && (
            <img
              className=" "
              src={clear}
              alt="clear"
              onClick={handleClear}
              style={{ margin: 'auto 5px auto' }}
            />
          )}
        </div>
      </div>
    </header>
  );
};
