import React from 'react';
import { Link } from 'react-router-dom';
import '../sass/MenuItem.scss';

export const MenuItem = ({ icon, title, description, path }) => {
  return (
    <Link to={path} className="nounderline">
      <div className="card-row">
        <div className="car-box">
          <div className="image-box">
            <img src={icon} alt={title} />
          </div>
          <div className="card-info">
            <h3>{title}</h3>
            {/* <p>{description}</p> */}
          </div>
          <button className="arrow_bt">
            <span className="material-symbols-outlined">
              keyboard_arrow_right
            </span>
          </button>
        </div>
      </div>
    </Link>
  );
};
