import React from 'react';

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.setState((state) => ({ ...state, hasError: true }));
    console.log('Error Occured:', error, info);
  }

  render() {
    if (this.state.hasError) {
      return <div>Something Went Wrong</div>;
    } else {
      return this.props.children;
    }
  }
}

export { ErrorBoundary };
