import React, { useState } from 'react';

import { NavHome } from './NavHome';

import { SearchList } from './SearchList';

export const SelectServiceType = ({
  title,
  handleSelectServiceType,
  prevSelectedServiceType,
  serviceTypes,
  ...props
}) => {
  const [selectedServiceTypes, setSelectedServiceTypes] = useState(
    prevSelectedServiceType ? prevSelectedServiceType : []
  );

  // useEffect(() => {
  //   CommonService.getServiceType().then((data) => {
  //     setServiceTypes(data);
  //   });
  // }, []);

  const handleChange = function (d) {
    let { id = null } = d;
    if (!id) return;

    let isExists = selectedServiceTypes.some((d) => d.id === id);
    if (isExists) {
      setSelectedServiceTypes(selectedServiceTypes.filter((d) => d.id !== id));
    } else {
      setSelectedServiceTypes([...selectedServiceTypes, d]);
    }
  };

  const handleClose = function (_) {
    handleSelectServiceType(selectedServiceTypes);
  };

  return (
    <div
      className="position-absolute top-0 start-0  min-vw-100 min-vh-100 d-flex flex-column"
      style={{ zIndex: 10 }}
    >
      <NavHome
        title="Select Service Type"
        goToHome={false}
        handleNonHomeBtn={handleClose}
      />

      <div className="search-result">
        <SearchList
          data={serviceTypes}
          handleClick={handleChange}
          type="serviceType"
          selectedServiceTypes={selectedServiceTypes}
        />

        <div className="filter-button">
          <div className="service-bt-wrap">
            <button
              className="reset-bt"
              onClick={() => setSelectedServiceTypes([])}
            >
              Reset
            </button>
            <button className="apply-bt" onClick={handleClose}>
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
