import React, { useEffect, useRef, useState } from 'react';
import '../sass/commonTemplate.css';
import ic_from from './../assets/mapNavigation/stratpoint.svg';
import ic_bus from './../assets/mapNavigation/busIndicator.svg';
import ic_current_stop from './../assets/mapNavigation/ic_current_stop.png';
import ic_to from './../assets/mapNavigation/endtrip.svg';
import ic_all_stop from './../assets/mapNavigation/ic_all_stop.png';
import ic_last_stop from './../assets/mapNavigation/ic_last_stop.png';
import arrival from './../assets/mapNavigation/arrival.svg';
import departure from './../assets/mapNavigation/departure.svg';
import endHalt from './../assets/mapNavigation/endHalt.svg';
import triptravelledlocationGreen from './../assets/mapNavigation/triptravelledlocationGreen.svg';
import startTrip from './../assets/mapNavigation/stratpoint.svg';
import endTrip from './../assets/mapNavigation/endtrip.svg';
import busIndicator from './../assets/mapNavigation/busIndicator.svg';
import { CurrentLocation, Direction } from './../components';

import {
  epochDatetoLocale,
  getClosestStopAgainstLatLng,
} from '../common/utils';
import useLocalStorage from '../hooks/useLocalStorage';

export function LeftPanelTripNavigation({
  tripData = null,
  vehicleLiveData = null,
  indexOfCurrentPreviousStopageViaLiveDataApi = null,
  dataFromTo = null,
  currentLatLongAndAddress = null,
}) {
  const [currentStop, setCurrentStop] = useState({
    //actually next stop for bottom section
    location: null,
    id: null,
    eta: null,
    status: null,
    isCurrentLastStoppage: false, //actual current stoppgae
  });

  const [showDirectionData, setShowDirectionData] = useState(null);

  const [selectedOperationType, _] = useLocalStorage('operationType', '_');

  const handleBusStopClick = function (d) {
    setShowDirectionData(d);
  };

  useEffect(() => {
    if (tripData?.points && vehicleLiveData.nextPointId) {
      let finalObj = {
        location: '',
        eta: '',
        status: '',
        isCurrentLastStoppage: false,
      };
      for (let i = 0; i < tripData?.points.length; i++) {
        if (tripData?.points[i].id === vehicleLiveData.nextPointId) {
          let status = '';

          if (tripData?.points[i].plannedTime && vehicleLiveData.gpsTime) {
            if (tripData?.points[i].plannedTime === vehicleLiveData.gpsTime)
              status = 'On Time';
            else if (tripData?.points[i].plannedTime < vehicleLiveData.gpsTime)
              status = 'Delayed';
            else status = 'On Time';
          } else {
            status = 'On Time';
          }
          finalObj['location'] = tripData?.points[i].name;
          finalObj['eta'] = tripData?.points[i].eta
            ? epochDatetoLocale(tripData?.points[i].eta, '24')
            : '';
          finalObj['status'] = status;
          // setCurrentStop({
          //   ...currentStop,
          //   location: tripData?.points[i].name,
          //   eta: tripData?.points[i].eta
          //     ? epochDatetoLocale(tripData?.points[i].eta, '24')
          //     : '',
          //   status: status,
          // });
        }

        if (
          i == tripData?.points.length - 1 &&
          tripData?.points[i].id == vehicleLiveData.currentPointId
        ) {
          finalObj['isCurrentLastStoppage'] = true;
        }
      }

      setCurrentStop({
        ...currentStop,
        location: finalObj.location,
        eta: finalObj.eta,
        status: finalObj.status,
        isCurrentLastStoppage: finalObj.isCurrentLastStoppage,
      });
    }
  }, []);

  let nearestCurrentStop =
    tripData?.points.length !== 0 && currentLatLongAndAddress?.latlng
      ? getClosestStopAgainstLatLng(
          tripData.points,
          currentLatLongAndAddress.latlng.split(',')
        )
      : null;

  return (
    <div className="search-left">
      {window.MapmyIndia &&
        window.MapmyIndia.direction &&
        showDirectionData && (
          <Direction
            handleClose={() => setShowDirectionData(null)}
            data={showDirectionData}
            currentLocationAddress={currentLatLongAndAddress}
          />
        )}

      <div className="route-input-section">
        {currentLatLongAndAddress?.address && (
          <div style={{ padding: '5px 10px 0 10px' }}>
            <CurrentLocation address={currentLatLongAndAddress?.address} />
          </div>
        )}

        <div
          className="route-running-main scroll-container"
          style={{
            maxHeight: currentLatLongAndAddress?.address
              ? 'calc(100vh - 290px)'
              : '',
          }}
        >
          <ul className="">
            {tripData?.points ? (
              tripData.points.map((d, i) => {
                // console.log(
                //   'id -- ',
                //   d.id,
                //   currentStop,
                //   currentStop?.nearestCurrentStop?.id
                // );
                return (
                  <li key={i}>
                    <div
                      className={`${
                        i !== tripData.points.length - 1
                          ? dataFromTo?.tripStatusForLeftPanel == 'completed'
                            ? 'route-searched-travelled-before-Vehicle'
                            : dataFromTo?.tripStatusForLeftPanel ==
                              'not started'
                            ? 'route-searched-nottravelled-after-Vehicle-before-destination'
                            : tripData.points[i].entryTime &&
                              tripData.points[i + 1].entryTime //bus crossed this point and reached next index //i <= dataFromTo.indexOfCompletedStoppageForLeftPanel
                            ? 'route-searched-travelled-before-Vehicle' //dar green case
                            : dataFromTo?.fromToLocation.from.indexOfFrom !=
                                null && //from to marker show -- //light green case
                              dataFromTo?.fromToLocation.to.indexOfTo &&
                              i >=
                                dataFromTo?.fromToLocation.from.indexOfFrom &&
                              i <= dataFromTo?.fromToLocation.to.indexOfTo
                            ? 'route-searched-nottravelled-after-Vehicle-before-destination'
                            : 'route-searched-default'
                          : ''
                      } route-running-theme timelineMarkerIdentifier`}
                    >
                      <div className="route-running-img">
                        <img
                          className=""
                          id={d.id}
                          src={
                            dataFromTo &&
                            dataFromTo.fromToLocation.from.indexOfFrom !=
                              null && //from to marker show
                            dataFromTo.fromToLocation.to.indexOfTo != null
                              ? i == dataFromTo.fromToLocation.from.indexOfFrom
                                ? ic_from
                                : i == dataFromTo.fromToLocation.to.indexOfTo
                                ? ic_to
                                : dataFromTo?.tripStatusForLeftPanel ==
                                    'completed' ||
                                  (i != 0 &&
                                    i != tripData.points.length &&
                                    tripData.points[i].entryTime &&
                                    (tripData.points[i - 1].entryTime ||
                                      tripData.points[i + 1].entryTime))
                                ? triptravelledlocationGreen
                                : i >
                                    dataFromTo.fromToLocation.from
                                      .indexOfFrom &&
                                  i < dataFromTo.fromToLocation.to.indexOfTo
                                ? ic_current_stop
                                : ic_all_stop
                              : i <=
                                indexOfCurrentPreviousStopageViaLiveDataApi.current
                              ? triptravelledlocationGreen
                              : ic_all_stop
                          }
                          alt=""
                        />
                      </div>
                      {i ==
                        indexOfCurrentPreviousStopageViaLiveDataApi.current &&
                        dataFromTo?.tripStatusForLeftPanel != 'not started' && (
                          <div
                            className="route-running-img liveVehicleMarker"
                            style={{
                              // height: '100px ',
                              // background: 'pink',
                              top:
                                i == tripData.points.length - 1 &&
                                dataFromTo?.tripStatusForLeftPanel ==
                                  'completed'
                                  ? '24px' //last stop
                                  : vehicleLiveData?.distanceFromPrevious
                                  ? 'calc(100% - 12px)' //60-48
                                  : '24px', //bus is at the stoppage and has not moved
                              zIndex: '16',
                            }}
                          >
                            <img className="" src={busIndicator} alt="" />
                          </div>
                        )}
                      <div
                        className={`${
                          d.id ==
                          (vehicleLiveData.currentPointId ||
                            vehicleLiveData.previousPointId)
                            ? i == tripData.points.length - 1 //last stoppage no need for timeline line
                              ? ''
                              : vehicleLiveData?.distanceFromPrevious
                              ? '' //'liveVehicleTimeline'
                              : '' //0 distance from stoppage hence no timeline
                            : ''
                        } route-running-text`}
                        // style={{ height: '20px' }}
                      >
                        {selectedOperationType === 'intra' ? (
                          <h2
                            className={
                              d.id === nearestCurrentStop?.id ? 'blink_me' : ''
                            }
                            onClick={() => handleBusStopClick(d)}
                            style={{ cursor: 'pointer' }}
                          >
                            {d.name}
                          </h2>
                        ) : (
                          <h2 style={{ cursor: 'pointer' }}>{d.name}</h2>
                        )}

                        <div className="route-running-time-sec">
                          <div className="route-running-time-item">
                            <span className="smallImgDiv">
                              <img className="smallImg" src={arrival} alt="" />
                            </span>
                            <span className="expected-time">
                              {selectedOperationType == 'intra'
                                ? d.entryTime || d.eta
                                  ? epochDatetoLocale(
                                      d.entryTime || d.eta,
                                      '24'
                                    )
                                  : '--:--'
                                : d.plannedTime
                                ? epochDatetoLocale(d.plannedTime, '24')
                                : '--:--'}
                            </span>
                            <span className="reached-time">
                              {selectedOperationType == 'inter'
                                ? d.entryTime
                                  ? epochDatetoLocale(d.entryTime, '24')
                                  : '--:--'
                                : null}
                            </span>
                          </div>
                          <div>
                            <div className="route-running-time-item">
                              <span className="smallImgDiv">
                                <img
                                  className="smallImg"
                                  src={departure}
                                  alt=""
                                />
                              </span>
                              <span className="expected-time">
                                {selectedOperationType == 'intra'
                                  ? d.exitTime || d.etd
                                    ? epochDatetoLocale(
                                        d.exitTime || d.etd,
                                        '24'
                                      )
                                    : '--:--'
                                  : d.plannedExitTime
                                  ? epochDatetoLocale(d.plannedExitTime, '24')
                                  : '--:--'}
                              </span>
                              <span className="reached-time">
                                {selectedOperationType == 'inter'
                                  ? d.exitTime
                                    ? epochDatetoLocale(d.exitTime, '24')
                                    : '--:--'
                                  : null}
                                <br />
                              </span>
                            </div>
                            <div style={{ color: 'red' }}>
                              {selectedOperationType == 'intra' &&
                              d.exitTime != null
                                ? 'Bus Left'
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <p className="p-2">No Route Found...</p>
            )}
          </ul>
        </div>
        <div className="current-stop-sec">
          <div
            className={`current-stop-text  ${
              currentStop.location ? 'w-60' : ''
            }`}
          >
            <h3>
              {dataFromTo?.tripStatusForLeftPanel == 'completed' ? (
                <span>Trip Completed</span>
              ) : dataFromTo?.tripStatusForLeftPanel == 'not started' ? (
                <>
                  <span>Trip Not Started</span>
                  {/* <br />
                  <span>
                    Next Stop:&nbsp;
                    <span>
                      {currentStop.location
                        ? currentStop.location
                        : ' Not Available'}
                    </span>
                  </span> */}
                </>
              ) : (
                <span>
                  Next Stop:&nbsp;
                  <span>
                    {currentStop.location
                      ? currentStop.location
                      : ' Not Available'}
                  </span>
                </span>
              )}
            </h3>

            {dataFromTo?.tripStatusForLeftPanel == 'started' &&
            !currentStop.isCurrentLastStoppage &&
            currentStop.eta ? (
              <p>
                Bus will arrive at <strong>{currentStop.eta}</strong>
              </p>
            ) : (
              ''
            )}
          </div>
          {dataFromTo?.tripStatusForLeftPanel == 'started' &&
          !currentStop.isCurrentLastStoppage &&
          currentStop.status ? (
            <div className="on-time-sec">
              <span className="material-symbols-outlined">schedule</span>
              <span className="time-status">{currentStop.status}</span>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}
