import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { NavHome, LeftPanelTripNavigation, MmiMap } from './../components';
import { CommonService } from '../services/api';

import useLocalStorage from '../hooks/useLocalStorage';

import {
  splitPolylineByClosestPolylinePoint,
  getGeoLocation,
} from './../common/utils';
import { mmiDataTypes } from '../common/types';

import refreshGreenBg from './../assets/mapNavigation/refreshGreenBg.svg';

let polylineStyles = {
  covered: {
    color: '#19bc9d',
    weight: 5,
    opacity: 1,
  },
  uncovered: {
    color: '#b8b8b6',
    weight: 5,
    opacity: 1,
  },
};

export function CommonSectionLRNav({
  navTitle = '',
  handleShouldTripMapComOpen,
  tripDetails = null,
}) {
  const handleComponentClose = (event) => {
    handleShouldTripMapComOpen(null);
  };

  const [tripData, setTripData] = useState(null);
  const [vehicleLiveData, setvehicleLiveData] = useState(null);
  const indexOfCurrentPreviousStopageViaLiveDataApi = useRef(null);
  const [refreshMe, setRefreshMe] = useState(0); //debouncing required

  const [currentLocation, setCurrentLocation] = useState({
    address: '',
    latlng: '',
  });

  const [selectedOperationType, _] = useLocalStorage('operationType', '_');

  let operationType =
    selectedOperationType === 'inter'
      ? 1
      : selectedOperationType === 'intra'
      ? 2
      : 1;

  useEffect(() => {
    getGeoLocation()
      .then((location) => {
        let { latitude, longitude } = location;
        CommonService.getLocationAddress(latitude, longitude).then((res) => {
          //console.log(res);
          setCurrentLocation({
            address: res.results[0].formatted_address,
            latlng: `${res.results[0].lat},${res.results[0].lng}`,
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    //console.log('useeffect is called from common tridetails- ', tripDetails);

    Promise.all([
      CommonService.tripsDetails(tripDetails?.id, operationType), //304588
      CommonService.vehiclesLiveDetails(tripDetails?.vehicleId),
    ])
      .then(function (values) {
        setTripData(values[0]);
        //setTripData(tripData1);
        setvehicleLiveData(values[1]);
        //setvehicleLiveData(vehicledatalive1);
      })
      .catch(() => {
        console.log('Api failed');
      });
  }, [refreshMe]);

  useEffect(() => {
    if (
      tripData &&
      vehicleLiveData &&
      Object.keys(tripData) &&
      Object.keys(vehicleLiveData)
    ) {
      let currentIdInDataPointsIndex = null,
        previousIdDataPointsIndex = null,
        currentIdInDataPoints = false,
        previousIdDataPoints = false;
      let entryTimeArray = [];
      for (let i in tripData?.points) {
        entryTimeArray.push(tripData.points[i]?.entryTime);

        if (
          tripData.points[i].id === vehicleLiveData?.currentPointId &&
          !currentIdInDataPoints
        ) {
          currentIdInDataPoints = true;
          currentIdInDataPointsIndex = i;
        }
        if (
          tripData.points[i].id == vehicleLiveData?.previousPointId &&
          !previousIdDataPoints
        ) {
          previousIdDataPoints = true;
          previousIdDataPointsIndex = i;
        }
      }

      if (currentIdInDataPoints) {
        indexOfCurrentPreviousStopageViaLiveDataApi.current =
          currentIdInDataPointsIndex;
      } else if (previousIdDataPoints) {
        indexOfCurrentPreviousStopageViaLiveDataApi.current =
          previousIdDataPointsIndex;
      }

      // console.log(
      //   'updated tripdata and vehicledatalive ,',
      //   tripData,
      //   vehicleLiveData
      // );
      // console.log('entryTimeArray-- ', entryTimeArray);
    }
  }, [tripData, vehicleLiveData]);

  const getFromToMmiData = (function () {
    if (tripDetails && tripData && tripData?.points) {
      let obj = {
        fromToLocation: {
          from: {
            from: [],
            indexOfFrom: null,
          },
          to: {
            to: [],
            indexOfTo: null,
          },
        },
        currentStopLocation: [],
        stopsLocation: [],
        polyline: [],
        indexOfCompletedStoppageForLeftPanel: null,
        tripStatusForLeftPanel: 'started',
      };

      obj.fromToLocation.from.from = tripData.points.find((d, i) => {
        if (d.id === tripDetails.from.id) {
          obj.fromToLocation.from.indexOfFrom = i;
          return true;
        }
      });

      obj.fromToLocation.to.to = tripData.points.find((d, i) => {
        if (d.id === tripDetails.to.id) {
          obj.fromToLocation.to.indexOfTo = i;
          return true;
        }
      });

      obj.currentStopLocation = [
        vehicleLiveData.latitude,
        vehicleLiveData.longitude,
      ];

      obj.stopsLocation = tripData.points.filter((d) => {
        if (d.id === tripDetails.from.id || d.id === tripDetails.to.id) {
          return false;
        }
        return true;
      });

      let segregatedRoute = tripData.points.reduce(
        (acc, curr, currIndex, arr) => {
          let point = [...curr?.geometry?.coordinates];
          if (point) {
            if (curr?.geometry?.type === 'Polygon') {
              let firstPolygonPoint = curr?.geometry?.coordinates[0][0];
              point = [...firstPolygonPoint];
            } else if (curr?.geometry?.type === 'Point') {
              point = point.slice().reverse();
            }
          }

          if (curr.exitTime && !arr[currIndex + 1]?.entryTime) {
            return [
              ...acc,
              {
                linecss: polylineStyles['covered'],
                point,
                type: `p${currIndex}`,
              },
            ];
          }

          if (!curr.exitTime && arr[currIndex + 1]?.entryTime) {
            return [
              ...acc,
              {
                linecss: polylineStyles['uncovered'],
                point,
                type: `p${currIndex}`,
              },
            ];
          }
          if (currIndex === arr.length - 1) {
            return [
              ...acc,
              {
                linecss: polylineStyles['uncovered'],
                point,
                type: `p${currIndex}`,
              },
            ];
          }

          return [...acc];
        },
        []
      );

      //console.log('a1', segregatedRoute);

      obj.polyline = splitPolylineByClosestPolylinePoint(
        [...segregatedRoute],
        tripData.polylinePoints
      );
      //console.log('a2', obj.polyline);

      let tempNullCount = 0,
        tempNotNullCount = 0;
      for (let j = 0; j < tripData.points.length; j++) {
        // if (
        //   tripData.points[j].entryTime != null ||
        //   tripData.points[j].exitTime != null
        // ) {
        //   //if both null --then above all is green
        //   obj.indexOfCompletedStoppageForLeftPanel = j;
        // }

        if (tripData.points[j].entryTime == null) {
          //if both null --then above all is green
          tempNullCount = tempNullCount + 1;
        } else {
          tempNotNullCount = tempNotNullCount + 1;
        }
      }

      if (tempNullCount == tripData.points.length && tripData.status <= 1) {
        obj.tripStatusForLeftPanel = 'not started';
      } else if (
        tempNotNullCount == tripData.points.length &&
        tripData.status > 1
      ) {
        obj.tripStatusForLeftPanel = 'completed';
      }

      // console.log(
      //   'obj.tripStatusForLeftPanel - tripData.status -- ',
      //   obj.tripStatusForLeftPanel,
      //   tripData.status
      // );
      return obj;
    }

    return null;
  })();

  return (
    <div
      className="bg-white position-absolute top-0 start-0 min-vw-100 min-vh-100 d-flex flex-column"
      style={{ zIndex: 10 }}
    >
      <NavHome
        title={navTitle}
        goToHome={false}
        handleNonHomeBtn={handleComponentClose}
      />
      <div className="d-flex m-0 flex-grow-1" style={{ height: '100%' }}>
        <div
          className="feedback__option bg-white p-0"
          style={{ minWidth: '300px', maxWidth: '24%' }}
        >
          {tripData &&
            vehicleLiveData &&
            Object.keys(tripData) &&
            Object.keys(vehicleLiveData) && (
              <LeftPanelTripNavigation
                tripData={tripData}
                vehicleLiveData={vehicleLiveData}
                indexOfCurrentPreviousStopageViaLiveDataApi={
                  indexOfCurrentPreviousStopageViaLiveDataApi
                }
                dataFromTo={getFromToMmiData}
                currentLatLongAndAddress={currentLocation}
              />
            )}
        </div>
        <div
          className="feedback__content position-relative flex-grow-1"
          style={{}}
        >
          <MmiMap
            currentLatLong={
              currentLocation.latlng ? currentLocation.latlng.split(',') : null
            }
            data={{ mmiDataType: mmiDataTypes.fromTo, value: getFromToMmiData }}
          />
          <img
            style={{
              position: 'absolute',
              bottom: '20px',
              right: '20px',
              zIndex: '1000',
              height: '70px',
              cursor: 'pointer',
            }}
            src={refreshGreenBg}
            alt="Refresh button"
            onClick={() => setRefreshMe((prevState) => prevState + 1)}
          />
        </div>
      </div>
    </div>
  );
}
