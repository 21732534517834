import React from 'react';

import arrowForwardPng from './../assets/mapNavigation/img/arrow-forword.png';

export const RecentTripHistory = function ({ data, handleClick }) {
  //console.log('data', data);
  let { from, to, serviceType } = data;

  // let serviceTypeId = '';
  // if (serviceType && serviceType?.length > 0) {
  //   serviceTypeId = serviceType.map((d) => d.id).join(',');
  // }
  // let { id: fromId } = from;
  // let { id: toId } = to;

  return (
    <div
      className="history-card cursorPointer"
      onClick={() => handleClick(data)}
    >
      <div className="icon-section">
        <span className="material-symbols-outlined">schedule</span>
      </div>
      <div className="content-section">
        <h3>
          <span title={from.name}>{from.name.substr(0, 8) + '...'}</span>{' '}
          <button>
            <img src={arrowForwardPng} alt="forward" />
          </button>{' '}
          <span title={to.name}>{to.name.substr(0, 8) + '...'}</span>
        </h3>
        <p title={serviceType && serviceType.map((d) => d.name).join('')}>
          Service Type:{' '}
          {serviceType &&
            serviceType
              .map((d) => d.name)
              .join('')
              .substr(0, 20) + '...'}
        </p>
      </div>
    </div>
  );
};
