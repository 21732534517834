import React, { useState } from 'react';
import { toggleItemArray } from './../common/utils';

import './../sass/FilterSection.scss';

import { ReactComponent as EarlyMorningSvg } from './../assets/filter/earlymorning.svg';
import { ReactComponent as DaySvg } from './../assets/filter/day.svg';
import { ReactComponent as NightSvg } from './../assets/filter/night.svg';

const byTimeFilterOptions = [
  {
    key: 1231,
    title: '6:00 am to 9:00 am',
    value: '6:00am-9:00am',
    icon: <EarlyMorningSvg />,
  },
  {
    key: 1241,
    title: '9:00 am to 12:00 pm',
    value: '9:00am-12:00pm',
    icon: <DaySvg />,
  },
  {
    key: 1251,
    title: '12:00 pm to 3:00 pm',
    value: '12:00pm-3:00pm',
    icon: <DaySvg />,
  },
  {
    key: 1261,
    title: '3:00 pm to 5:00 pm',
    value: '3:00pm-5:00pm',
    icon: <DaySvg />,
  },
  {
    key: 1271,
    title: '6:00 pm to 10:00 pm',
    value: '6:00pm-10:00pm',
    icon: <NightSvg />,
  },
  {
    key: 1281,
    title: '10:00 pm to 12:00 am',
    value: '10:00pm-12:00am',
    icon: <NightSvg />,
  },
];

export const FilterSection = ({
  isFilterSidebarOpen,
  handleFilterOpen,
  type = null,
  busData = [],
  handleApply,
  prevFilterParams = null,
}) => {
  const [filterParams, setFilterParams] = useState({
    ...prevFilterParams,
  });

  const handleApplyClick = function () {
    handleApply(filterParams);
    handleFilterOpen();
  };
  const handleResetClick = function () {
    setFilterParams({ time: [], busType: [] });
  };

  const handleFilterOptionClick = function (type, value) {
    setFilterParams({
      ...filterParams,
      [type]: toggleItemArray(filterParams[type], value),
    });
  };

  return (
    <section
      className={`filter-section ${isFilterSidebarOpen ? 'filter-open' : ''}`}
    >
      <div className="filter-wrap">
        <div className="filter-header">
          <button>
            <span
              className="material-symbols-outlined"
              onClick={handleFilterOpen}
            >
              close
            </span>
          </button>
          <h2>Filter</h2>
        </div>
        <div className="filter-body">
          <div className="filter-heading">
            <h3>By Time</h3>
          </div>
          <div className="filter-label-section">
            {byTimeFilterOptions.map((d) => {
              return (
                <div
                  className={`filter-label ${
                    filterParams.time.includes(d.value) ? 'active' : ''
                  }`}
                  key={d.key}
                  onClick={() => handleFilterOptionClick('time', d.value)}
                >
                  <div
                    style={{
                      marginRight: '5px',
                    }}
                  >
                    {d.icon}
                  </div>
                  <h4>{d.title}</h4>
                </div>
              );
            })}
          </div>
          {type === 'fromTo' && (
            <>
              {' '}
              <div className="filter-heading">
                <h3>Bus Type</h3>
              </div>
              <div className="filter-label-section">
                {busData &&
                  busData.length > 0 &&
                  busData.map((d) => {
                    return (
                      <div
                        className={`filter-label ${
                          filterParams.busType.includes(d.name) ? 'active' : ''
                        }`}
                        key={d.id}
                        onClick={() =>
                          handleFilterOptionClick('busType', d.name)
                        }
                      >
                        <h4>{d.name}</h4>
                      </div>
                    );
                  })}
              </div>
            </>
          )}
        </div>

        <div className="filter-button">
          <button className="reset-bt" onClick={handleResetClick}>
            Reset
          </button>
          <button className="apply-bt" onClick={handleApplyClick}>
            Apply
          </button>
        </div>
      </div>
    </section>
  );
};
