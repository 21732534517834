import { mmiDataTypes } from './types';
import ic_current_stop from '../assets/mapNavigation/ic_current_stop.png';
import busStop1Svg from '../assets/bus-stop-1.svg';

export const debouncedFn = (fn, delay) => {
  let timerId;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
};

export const objectsEqual = (o1, o2) =>
  typeof o1 === 'object' && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
    : o1 === o2;

export const arrayOfObjectsEqual = (a1, a2) => {
  if (a1.length > 0 && a2.length > 0) {
    return (
      a1 &&
      a2 &&
      a1.length === a2.length &&
      a1.every((o, idx) => objectsEqual(o, a2[idx]))
    );
  }
  return a1 && a2 && a1.length === a2.length;
};

export function epochDatetoLocale(date1, type = '12') {
  let myDate = new Date(date1 * 1000);

  let dt = myDate.toString().split(' ').slice(1, 4); // [ "Apr", "15", "2022", ]
  let modifiedTime;
  if (type == '24') {
    modifiedTime =
      (myDate.getHours() <= 9 ? '0' + myDate.getHours() : myDate.getHours()) +
      ':' +
      (myDate.getMinutes().toString().length <= 1
        ? '0' + myDate.getMinutes()
        : myDate.getMinutes());
  } else {
    let dtTime = myDate.toLocaleString().split(',')[1].toUpperCase().trim(); // 10:20:01 AM
    let dtTimebyspacecolon = dtTime.split(' ')[0].split(':');
    modifiedTime =
      dt[1] +
      ' ' +
      dt[0] +
      ' ' +
      dtTimebyspacecolon[0] +
      ':' +
      dtTimebyspacecolon[1] +
      ' ' +
      dtTime.split(' ')[1];
  }

  //let result = `${dtTime} ${dt[1]} ${dt[0]} ${dt[2]}`;
  let result = modifiedTime;
  //console.log('-- ', result);
  return result;
}

export function timeMinToHr(n) {
  let num = n;
  let hours = num / 60;
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);
  return `${rhours} hour(s) ${rminutes > 0 ? `and ${rminutes} minute(s)` : ``}`;
}

export function timeSecToHr(n) {
  let num = n;
  let hours = num / 3600;
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);
  return `${rhours} hour(s) ${rminutes > 0 ? `and ${rminutes} minute(s)` : ``}`;
}

export function getGeoLocation() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      function (event) {
        let latitude = event.coords.latitude;
        let longitude = event.coords.longitude;
        resolve({ latitude, longitude });
      },
      function (error) {
        console.log('Error', error);
        reject(error);
      }
    );
  });
}

export const toggleItemArray = (arr, item) =>
  arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item];

/**
 *
 * @param {*} breakpoints
 * @param {*} polyline
 * @returns {object} of splitted polyline ( split using closest distance between point latlng and polyline latlng) with styling
 */
export function splitPolylineByClosestPolylinePoint(
  breakpoints = [],
  polyline = []
) {
  if (breakpoints.length === 0) return polyline;
  let obj = {};
  for (let j = 0; j < breakpoints.length; j++) {
    obj[breakpoints[j].type] = {
      polyline: [],
      minDist: null,
      minDestLatLng: null,
      breakpoint: breakpoints[j]['point'],
      idx: null,
      type: breakpoints[j].type,
      linecss: breakpoints[j].linecss,
    };
  }
  let pointsArr = [];
  for (let k in obj) {
    let minDist = 9999999;
    let i = pointsArr.length ? pointsArr[pointsArr.length - 1].idx : 0;
    for (i; i < polyline.length; i++) {
      let lat1 = obj[k].breakpoint[0];
      let lon1 = obj[k].breakpoint[1];
      let lat2 = polyline[i].lat;
      let lon2 = polyline[i].lng;
      if (!lat2 || !lat1) continue;
      let x1 = lat2 - lat1;
      let dLat = x1.toRad();
      let x2 = lon2 - lon1;
      let dLon = x2.toRad();
      let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1.toRad()) *
          Math.cos(lat2.toRad()) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      let d = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      if (minDist > d) {
        minDist = d;
        obj[k]['idx'] = i;
        obj[k]['minDist'] = d;
        obj[k]['minDestLatLng'] = polyline[i];
      }
    }
    let start = pointsArr.length ? pointsArr[pointsArr.length - 1].idx : 0;
    let end =
      pointsArr.length < breakpoints.length - 1
        ? obj[k]['idx'] + 1
        : polyline.length;
    //console.log(start, end);
    obj[k]['polyline'] = [...polyline.slice(start, end)];
    pointsArr.push(obj[k]);
  }
  return obj;
}

/**
 * Coordinate must be in format [lat,lng]
 * @param {*} stops Array of objects containing geometry and type
 * @param {*} latlng Array containing latitude and longitude
 * @returns object whose coordinates are closed to provided latlng
 */
export const getClosestStopAgainstLatLng = (stops = [], latlng) => {
  let minDist = 9999999;
  let minDistPoint = null;

  let [lat1, lon1] = latlng;
  lat1 = Number(lat1);
  lon1 = Number(lon1);

  for (let i = 0; i < stops.length; i++) {
    let lat2 = null;
    let lon2 = null;
    if (stops[i]?.geometry?.type === 'Point') {
      lat2 = stops[i]?.geometry?.coordinates[1];
      lon2 = stops[i]?.geometry?.coordinates[0];
    }
    if (stops[i]?.geometry?.type === 'Polygon') {
      let firstPolygonPoint = stops[i]?.geometry.coordinates[0][0];
      firstPolygonPoint = firstPolygonPoint.slice().reverse();

      lat1 = firstPolygonPoint[0];
      lon1 = firstPolygonPoint[1];
    }
    if (!lat2 || !lat1) continue;
    let x1 = lat2 - lat1;
    let dLat = x1.toRad();
    let x2 = lon2 - lon1;
    let dLon = x2.toRad();

    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1.toRad()) *
        Math.cos(lat2.toRad()) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    let d = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    if (minDist > d) {
      minDist = d;
      minDistPoint = stops[i];
    }
  }
  return minDistPoint;
};

export function addMarkerOnMap(latlng = [], options = {}, popupOptions = null) {
  let marker = window.L.marker([...latlng], {
    ...options,
  });
  if (popupOptions) {
    marker.addTo(window.mmiState.mmiLayerGroup).bindPopup(popupOptions);
  } else {
    marker.addTo(window.mmiState.mmiLayerGroup);
  }
  return marker;
}
export function addPolygonOnMap(polygonPoints = [], options = {}) {
  let polygon = window.L.polygon(polygonPoints, {
    ...options,
  });
  polygon.addTo(window.mmiState.mmiLayerGroup);
  return polygon;
}

/**
 * Expects an array of points containing geometry and a mmiDataType
 * @param {Array} layers
 * @param {String} mmiDataType
 * @returns
 */
export function addMultipleLayersOnMap(layers, mmiDataType = null) {
  for (let layer of layers) {
    let { geometry = null } = layer;

    if (
      !geometry ||
      !geometry?.coordinates ||
      !geometry?.coordinates?.length > 0
    )
      return;

    let { type = '', coordinates = [] } = geometry;

    if (type === 'Point') {
      let latlng = [...coordinates].reverse();
      if (mmiDataType === mmiDataTypes.nearestBusStop) {
        addMarkerOnMap(latlng, {
          icon: window.L.icon({
            iconUrl: busStop1Svg,
            iconSize: [30, 30],
          }),
        });
      } else if (mmiDataType === mmiDataTypes.fromTo) {
        addMarkerOnMap(
          latlng,
          {
            icon: window.L.icon({
              iconUrl: ic_current_stop,
              iconSize: [15, 15],
            }),
          },
          `<span>Name: ${layer.name}</span><br><span> ${
            layer.entryTime
              ? `Entry Time : ${epochDatetoLocale(layer.entryTime)}`
              : layer.eta
              ? `Estimated Time: ${epochDatetoLocale(layer.eta)}`
              : ''
          }</span>`
        );
      }
    } else if (type === 'Polygon') {
      coordinates.forEach((c) => {
        let polygonPoints = c.map((d) => d.slice().reverse());
        addPolygonOnMap(polygonPoints, {
          color: '#19bc9d',
        });
      });
    }
  }
}
