import React, { useEffect, useRef, useState } from 'react';

import bgBus from '../assets/bgBus.svg';
import smBus from '../assets/smbus.svg';
import { CommonSectionLRNav, TripInfoCard, SearchBar } from '../components';
import { SearchList } from './../components/SearchList';
import { CommonService } from '../services/api';
import useLocalStorage from '../hooks/useLocalStorage';
import busIconFront from './../assets/mapNavigation/busIconFront.svg';
import { BusStopItem } from './SearchNearestBusStopPage';

export function SearchByBusNumberPage() {
  const [vehicleListData, setVehicleListData] = useState([]);
  const [tripListData, setTripListData] = useState([]);
  const [shouldTripMapComOpen, setShouldTripMapComOpen] = useState(false);
  const submittedRef = useRef({
    value: '',
    submitted: false,
  });
  const [selectedOperationType, _] = useLocalStorage('operationType', '_');

  const [searchTerm, setSearchTerm] = useState('');
  const [recentTripHistory, setRecentTripHistory] = useLocalStorage(
    'recentTripHistoryForSearchByBusNumber',
    []
  );
  const [showHistory, setShowHistory] = useState(false);
  const [vehicleListDataFiltered, setVehicleListDataFiltered] = useState([]);

  const [selectedTripData, setSelectedTripData] = useState(null);
  const [selectedSearchType, setSelectedSearchType] = useState('bus');

  let operationType =
    selectedOperationType === 'inter'
      ? 1
      : selectedOperationType === 'intra'
      ? 2
      : 1;

  const handleShouldTripMapComOpen = () => {
    setShouldTripMapComOpen(!shouldTripMapComOpen);
  };
  //taken from to page
  const data1 = [
    {
      id: 1485733,
      serviceId: 624737,
      vehicleId: 993779,
      vehicleNumber: 'TS10UB7966',
      totalStops: 15,
      duration: 4020,
      from: {
        id: 680514,
        name: 'Contonment Depot',
        plannedTime: 1653025590,
        plannedExitTime: null,
        //eta: 1653030858,
        etd: null,
        //entryTime: null,
        exitTime: null,
        sequence: null,
        buffer: null,
        geometry: null,
      },
      to: {
        id: 680529,
        name: 'RGI Airport Domestic Departures Bus Stop',
        plannedTime: 1653029610,
        plannedExitTime: null,
        eta: 1653034873,
        etd: null,
        entryTime: null,
        exitTime: null,
        sequence: null,
        buffer: null,
        geometry: null,
      },
    },
    {
      id: 1485735,
      serviceId: 625985,
      vehicleId: 993774,
      vehicleNumber: 'TS10UB6784',
      totalStops: 13,
      duration: 6928,
      from: {
        id: 680514,
        name: 'Contonment Depot',
        plannedTime: 1653025640,
        plannedExitTime: null,
        eta: 1653030929,
        etd: null,
        entryTime: null,
        exitTime: null,
        sequence: null,
        buffer: null,
        geometry: null,
      },
      to: {
        id: 680529,
        name: 'RGI Airport Domestic Departures Bus Stop',
        plannedTime: 1653032569,
        plannedExitTime: null,
        eta: 1653037788,
        etd: null,
        entryTime: null,
        exitTime: null,
        sequence: null,
        buffer: null,
        geometry: null,
      },
    },
    {
      id: 1485737,
      serviceId: 625452,
      vehicleId: 993786,
      vehicleNumber: 'TS10UB8168',
      totalStops: 15,
      duration: 6794,
      from: {
        id: 680530,
        name: 'RGI Airport Pick Up Point E Bus Stop',
        plannedTime: 1653025729,
        plannedExitTime: null,
        eta: null,
        etd: null,
        entryTime: null,
        exitTime: null,
        sequence: null,
        buffer: null,
        geometry: null,
      },
      to: {
        id: 680515,
        name: 'JBS (Jubilee) Bus Station',
        plannedTime: 1653032525,
        plannedExitTime: null,
        eta: 1653043101,
        etd: null,
        entryTime: null,
        exitTime: null,
        sequence: null,
        buffer: null,
        geometry: null,
      },
    },
    {
      id: 1485738,
      serviceId: 625452,
      vehicleId: 993784,
      vehicleNumber: 'TS10UB6783',
      totalStops: 15,
      duration: 6794,
      from: {
        id: 680530,
        name: 'RGI Airport Pick Up Point E Bus Stop',
        plannedTime: 1653025769,
        plannedExitTime: null,
        eta: null,
        etd: null,
        entryTime: null,
        exitTime: null,
        sequence: null,
        buffer: null,
        geometry: null,
      },
      to: {
        //id: 680515,
        id: 681258,
        name: 'JBS (Jubilee) Bus Station',
        plannedTime: 1653032565,
        plannedExitTime: null,
        eta: 1653035206,
        etd: null,
        entryTime: null,
        exitTime: null,
        sequence: null,
        buffer: null,
        geometry: null,
      },
    },
  ];

  const vehicledatalive1 = {
    gpsTime: 1649132177,
    gprsTime: 1581921354,
    latitude: 26.472656,
    longitude: 80.322783,
    heading: 0,
    speedKph: 66,
    address: 'Bhatia X Ray & Pathology',
    lastStatusUpdate: null,
    status: 1,
    previousPointId: null,
    nextPointId: 295670,
    currentPointId: 295667,
    distanceFromPrevious: 0.77, //0.774464536652336
    statusStr: 'Moving',
    id: 67808,
    vehicleNumber: 'DL3SDS1836',
    serviceId: null,
    tripId: 304588,
    serviceName: null,
  };

  const tripData1 = {
    id: 304588,
    serviceId: 41762,
    serviceName: 'MMI Office To Home Drop',
    vehicleId: 67808,
    vehicleNumber: 'DL3SDS1836',
    serviceType: 'AMARAVATHI MULTIAXLE AC',
    statusStr: 'Active',
    status: 1,
    points: [
      {
        id: 295665,
        name: 'Region',
        plannedTime: 1649132018,
        plannedExitTime: 1649132318,
        eta: null,
        etd: null,
        entryTime: 1649132218,
        exitTime: 1649132318,
        sequence: 1,
        buffer: 300,
        geometry: {
          type: 'Point',
          coordinates: [80.328078, 26.470227],
        },
      },
      {
        id: 295667,
        name: 'Bhatia X Ray & Pathology',
        plannedTime: 1649132177,
        plannedExitTime: 1649132477,
        eta: 1649132177,
        etd: 1649132477,
        entryTime: null,
        exitTime: null,
        sequence: 2,
        buffer: 300,
        geometry: {
          type: 'Point',
          coordinates: [80.325322, 26.466072],
        },
      },
      {
        id: 295668,
        name: 'Disha Path Lab',
        plannedTime: 1649132635,
        plannedExitTime: 1649132935,
        eta: null,
        etd: 1649132935,
        entryTime: null,
        exitTime: null,
        sequence: 3,
        buffer: 300,
        geometry: {
          type: 'Point',
          coordinates: [80.31601, 26.472852],
        },
      },
      {
        id: 295670,
        name: 'Dr. D. SARAF',
        plannedTime: 1649132844,
        plannedExitTime: 1649133144,
        eta: null,
        etd: 1649133144,
        entryTime: null,
        exitTime: null,
        sequence: 4,
        buffer: 300,
        geometry: {
          type: 'Point',
          coordinates: [80.322783, 26.472656],
        },
      },
    ],
    polylinePoints: [
      {
        lat: 26.46608,
        lng: 80.32532,
      },
      {
        lat: 26.46617,
        lng: 80.32538,
      },
      {
        lat: 26.46644,
        lng: 80.32555,
      },
      {
        lat: 26.46675,
        lng: 80.32574,
      },
      {
        lat: 26.46698,
        lng: 80.32589,
      },
      {
        lat: 26.46726,
        lng: 80.32608,
      },
      {
        lat: 26.46753,
        lng: 80.32625,
      },
      {
        lat: 26.46782,
        lng: 80.32645,
      },
      {
        lat: 26.4681,
        lng: 80.32662,
      },
      {
        lat: 26.46836,
        lng: 80.32679,
      },
      {
        lat: 26.46862,
        lng: 80.32696,
      },
      {
        lat: 26.46889,
        lng: 80.32713,
      },
      {
        lat: 26.46912,
        lng: 80.32727,
      },
      {
        lat: 26.46939,
        lng: 80.32745,
      },
      {
        lat: 26.46961,
        lng: 80.32759,
      },
      {
        lat: 26.46965,
        lng: 80.32762,
      },
      {
        lat: 26.46988,
        lng: 80.32778,
      },
      {
        lat: 26.47003,
        lng: 80.32789,
      },
      {
        lat: 26.4701,
        lng: 80.32794,
      },
      {
        lat: 26.47014,
        lng: 80.32797,
      },
      {
        lat: 26.47025,
        lng: 80.32805,
      },
      {
        lat: 26.47036,
        lng: 80.32814,
      },
      {
        lat: 26.47059,
        lng: 80.32774,
      },
      {
        lat: 26.47078,
        lng: 80.32742,
      },
      {
        lat: 26.47103,
        lng: 80.32696,
      },
      {
        lat: 26.47117,
        lng: 80.32667,
      },
      {
        lat: 26.47127,
        lng: 80.32644,
      },
      {
        lat: 26.4713,
        lng: 80.32637,
      },
      {
        lat: 26.47138,
        lng: 80.3261,
      },
      {
        lat: 26.4714,
        lng: 80.32595,
      },
      {
        lat: 26.47146,
        lng: 80.32575,
      },
      {
        lat: 26.47156,
        lng: 80.32542,
      },
      {
        lat: 26.47163,
        lng: 80.32515,
      },
      {
        lat: 26.47233,
        lng: 80.3254,
      },
      {
        lat: 26.47236,
        lng: 80.32524,
      },
      {
        lat: 26.47237,
        lng: 80.32503,
      },
      {
        lat: 26.47238,
        lng: 80.32492,
      },
      {
        lat: 26.47239,
        lng: 80.32483,
      },
      {
        lat: 26.4724,
        lng: 80.3248,
      },
      {
        lat: 26.47243,
        lng: 80.32478,
      },
      {
        lat: 26.47263,
        lng: 80.32469,
      },
      {
        lat: 26.47263,
        lng: 80.32463,
      },
      {
        lat: 26.47264,
        lng: 80.32459,
      },
      {
        lat: 26.47266,
        lng: 80.32456,
      },
      {
        lat: 26.47268,
        lng: 80.32454,
      },
      {
        lat: 26.4727,
        lng: 80.32453,
      },
      {
        lat: 26.47273,
        lng: 80.32452,
      },
      {
        lat: 26.4727,
        lng: 80.32392,
      },
      {
        lat: 26.47267,
        lng: 80.32331,
      },
      {
        lat: 26.47261,
        lng: 80.32284,
      },
      {
        lat: 26.47254,
        lng: 80.32257,
      },
      {
        lat: 26.47247,
        lng: 80.3224,
      },
      {
        lat: 26.47219,
        lng: 80.32181,
      },
      {
        lat: 26.47254,
        lng: 80.32163,
      },
      {
        lat: 26.47292,
        lng: 80.32144,
      },
      {
        lat: 26.4728,
        lng: 80.32113,
      },
      {
        lat: 26.47275,
        lng: 80.32091,
      },
      {
        lat: 26.47271,
        lng: 80.32073,
      },
      {
        lat: 26.47268,
        lng: 80.32052,
      },
      {
        lat: 26.47267,
        lng: 80.32032,
      },
      {
        lat: 26.47266,
        lng: 80.32014,
      },
      {
        lat: 26.47265,
        lng: 80.31943,
      },
      {
        lat: 26.47265,
        lng: 80.31902,
      },
      {
        lat: 26.47267,
        lng: 80.31855,
      },
      {
        lat: 26.4727,
        lng: 80.31807,
      },
      {
        lat: 26.47273,
        lng: 80.31754,
      },
      {
        lat: 26.47275,
        lng: 80.31701,
      },
      {
        lat: 26.47276,
        lng: 80.31683,
      },
      {
        lat: 26.47279,
        lng: 80.31625,
      },
      {
        lat: 26.47281,
        lng: 80.31601,
      },
      {
        lat: 26.47279,
        lng: 80.31625,
      },
      {
        lat: 26.47276,
        lng: 80.31683,
      },
      {
        lat: 26.47275,
        lng: 80.31701,
      },
      {
        lat: 26.47273,
        lng: 80.31754,
      },
      {
        lat: 26.4727,
        lng: 80.31807,
      },
      {
        lat: 26.47267,
        lng: 80.31855,
      },
      {
        lat: 26.47265,
        lng: 80.31902,
      },
      {
        lat: 26.47265,
        lng: 80.31943,
      },
      {
        lat: 26.47266,
        lng: 80.32014,
      },
      {
        lat: 26.47267,
        lng: 80.32032,
      },
      {
        lat: 26.47268,
        lng: 80.32052,
      },
      {
        lat: 26.47271,
        lng: 80.32073,
      },
      {
        lat: 26.47275,
        lng: 80.32091,
      },
      {
        lat: 26.4728,
        lng: 80.32113,
      },
      {
        lat: 26.47292,
        lng: 80.32144,
      },
      {
        lat: 26.47254,
        lng: 80.32163,
      },
      {
        lat: 26.47219,
        lng: 80.32181,
      },
      {
        lat: 26.47247,
        lng: 80.3224,
      },
      {
        lat: 26.47254,
        lng: 80.32257,
      },
      {
        lat: 26.4726,
        lng: 80.3228,
      },
    ],
  };
  const handleBusClickForTrips = (data) => {
    // console.log('search bus trips ', data);
    if (!submittedRef.current.submitted) {
      submittedRef.current.submitted = true; //just once for bg change
    }
    submittedRef.current.value = data?.name;

    CommonService.searchByVehicleId(data?.id, operationType).then((result) => {
      setTripListData(result);
      //setTripListData(data1);
    });
  };

  const handleCardClick = (data = null) => {
    if (shouldTripMapComOpen) {
      setSelectedTripData(data); //if already opened then set tripselected data to null
    } else {
      setSelectedTripData(data);
    }
  };

  const initialRender = useRef(true);
  useEffect(() => {
    if (!initialRender.current) {
      handleShouldTripMapComOpen();
    }
    initialRender.current = false;
  }, [selectedTripData]);

  const handleClickToUpateRecentHistoryAndBusSearch = function (data) {
    let { id = null } = data;
    if (!id) return;
    let isExists = recentTripHistory.some((d) => d.id === id);
    if (!isExists) {
      setRecentTripHistory([...recentTripHistory, data]);
    }
    handleBusClickForTrips(data); //to show same behaviour when clicked on bus number
  };

  const handleInput = function (s) {
    if (showHistory) setShowHistory(false);

    setSearchTerm(s);
  };

  useEffect(() => {
    let filteredData = [];
    if (searchTerm) {
      filteredData = vehicleListData.filter((d) =>
        d.name.toString().includes(searchTerm)
      );
    } else {
      filteredData = vehicleListData;
    }

    setVehicleListDataFiltered(filteredData);
  }, [searchTerm]);

  const handleSearchBarClick = function () {
    // console.log('SearchBarClicked');
    if (searchTerm == '' && recentTripHistory.length > 0) {
      setShowHistory(true);
    }
  };

  const updateBusList = function (operationType) {
    return CommonService.allVehicles(operationType).then((res) => {
      setVehicleListData(res);
      setVehicleListDataFiltered(res);
    });
  };

  useEffect(() => {
    updateBusList(operationType);
  }, []);

  return (
    <div
      className="container-fluid flex-grow-1"
      style={{ padding: '0', margin: '0' }}
    >
      {shouldTripMapComOpen && (
        <CommonSectionLRNav
          navTitle={selectedTripData?.vehicleNumber}
          handleShouldTripMapComOpen={handleCardClick}
          tripDetails={selectedTripData}
        />
      )}
      <div className="d-flex m-0" style={{ height: '100%' }}>
        <div
          className="search-left bg-white feedback__option  p-0"
          style={{ border: 'none' }}
        >
          <header className="header-section">
            <div className="tab-section">
              <ul
                className="nav nav-tabs tab_box"
                style={{ paddingLeft: '5px' }}
              >
                <li className="nav-item">
                  <button
                    className={`${
                      selectedSearchType === 'bus' ? 'active' : ''
                    }`}
                    type="button"
                    onClick={() => setSelectedSearchType('bus')}
                  >
                    Bus Number
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    type="button"
                    className={`${
                      selectedSearchType === 'route' ? 'active' : ''
                    }`}
                    onClick={() => setSelectedSearchType('route')}
                  >
                    Route Number{' '}
                  </button>
                </li>
              </ul>
            </div>
          </header>
          <SearchBar
            placeholder={`${
              selectedSearchType === 'bus'
                ? 'Search by Bus Number'
                : 'Search by Route Number'
            }`}
            required={false}
            goToHome={true}
            handleInput={handleInput}
            handleSearchBarClick={handleSearchBarClick}
            forBusRouteSearch={true}
          />
          {showHistory ? (
            <>
              <SearchList
                data={[...recentTripHistory]}
                handleClick={handleClickToUpateRecentHistoryAndBusSearch}
                type={'recentBusHistory'}
              />
            </>
          ) : (
            <>
              <div
                className="total-bus-stops mb-0"
                style={{
                  padding: '5px 16px',
                  background: '#FAFAFA',
                }}
              >
                <strong>
                  {selectedSearchType === 'bus' ? 'Bus Number' : 'Route Number'}
                </strong>
                <strong>
                  {selectedSearchType === 'bus' ? 'Vehicle Class' : ''}
                </strong>
              </div>
              <div
                className="left-side-section scroll-container "
                style={{
                  padding: '10px 16px 24px 16px',
                  height: 'calc(100vh - 90px)',
                  backgroundColor: 'white',
                }}
              >
                <div className="bus-stops-container">
                  {vehicleListDataFiltered.length > 0 ? (
                    vehicleListDataFiltered.map((d) => {
                      return (
                        <BusStopItem
                          key={d.id}
                          title={d.registrationNumber}
                          icon={busIconFront}
                          data={d}
                          serviceType={d.serviceType}
                          showDistance={false}
                          handleClick={
                            handleClickToUpateRecentHistoryAndBusSearch
                          }
                        />
                      );
                    })
                  ) : (
                    <p>No Vehicle Available</p>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <div
          className="feedback__content  pb-4"
          style={{
            background: submittedRef.current.submitted
              ? '#fafafa'
              : `#fafafa url(${bgBus}) no-repeat center`,
            height: '100vh',
          }}
        >
          {submittedRef.current.submitted && (
            <div>
              <p className="w-60 mx-auto text-center mt-2">
                <b>{submittedRef.current.value}</b>
                <span
                  className="infoDetails__heading"
                  style={{ fontSize: '13px' }}
                >
                  <b>&nbsp;Service Number Result</b>
                </span>
              </p>
              {tripListData.length > 0 ? (
                tripListData.map((d, i) => {
                  return (
                    <TripInfoCard
                      icon={smBus}
                      // handleShouldTripMapComOpen={handleShouldTripMapComOpen}
                      data={d}
                      key={i}
                      handleCardClick={handleCardClick}
                    />
                  );
                })
              ) : (
                <center>
                  <h4>No Trips Available</h4>
                </center>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
