import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab="home" />);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// adding toRad property to Number
Number.prototype.toRad = function () {
  return (this * Math.PI) / 180;
};

// add map_sdk_plugins with updated token automatically
let map_sdk_plugin_expireTime = null;
let map_sdk_script = null;

function runWhenTokenExpired(time) {
  console.log('time', time);
  setTimeout(async () => {
    console.log('loadMapSdkPlugin timeout cb called');
    await loadMapSdkPlugin();
    runWhenTokenExpired(map_sdk_plugin_expireTime);
  }, time);
}

function loadMapSdkPlugin() {
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    let urlencoded = new URLSearchParams();
    urlencoded.append('grant_type', 'client_credentials');
    urlencoded.append(
      'client_id',
      'zvAGX9G0VhoKHSIzgHVfEbff9Xzhjc3pjYfZpo_KHaIzDEjOijHYLSR5hKC4xq7WjpLsR7AiC-8='
    );
    urlencoded.append(
      'client_secret',
      'ebEc8GH231dbH7OxBgfmD1yGm2EBInNTYUp69Tr54WDrzhSMpvvRQTVFF-KNf3SDoB5chFdQw0SjfSKX2nod6Q=='
    );

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
    };

    fetch('https://outpost.mappls.com/api/security/oauth/token', requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let parsedResponse = JSON.parse(result);
        // settimeout in miliseconds
        map_sdk_plugin_expireTime = parsedResponse.success.expires_in * 1000;

        // remove script if present already
        if (map_sdk_script) {
          map_sdk_script.parentNode.removeChild(map_sdk_script);
        }

        let token = parsedResponse.success.access_token;
        let head = document.getElementsByTagName('head')[0];
        map_sdk_script = document.createElement('script');

        map_sdk_script.src = `https://apis.mapmyindia.com/advancedmaps/api/${token}/map_sdk_plugins`;
        map_sdk_script.type = 'text/javascript';

        //script.onload = console.log('map sdk loaded');

        head.appendChild(map_sdk_script);
        resolve();
      })
      .catch((error) => {
        console.log('error', error);
        reject(error);
      });
  });
}

async function initLoadMapSdkPlugin() {
  await loadMapSdkPlugin();
  runWhenTokenExpired(map_sdk_plugin_expireTime);
}
initLoadMapSdkPlugin();

(function () {
  let head = document.getElementsByTagName('head')[0];
  let script = document.createElement('script');
  script.src = `https://apis.mapmyindia.com/advancedmaps/v1/d088323a3a8ae9d1e8a08aa36f2b8a5c/map_load?v=1.5`;
  script.type = 'text/javascript';

  head.appendChild(script);
})();
