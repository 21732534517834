import React from 'react';
import { NavHome } from '../components';

import circleSvg from './../assets/notification/current_location.svg';
import logo from './../assets/notification/logo1x.png';
import busSvg from './../assets/notification/bus.svg';

export const NotificationsPage = () => {
  return (
    <div className="container-fluid px-0">
      <NavHome title="Notifications" />

      <div className="d-flex m-4 mb-0">
        <div className="p-3 pe-1">
          <img
            src={busSvg}
            alt="icon as per the notification"
            style={{
              border: '1px solid black',
              borderRadius: '50%',
              width: '30px',
            }}
          />
        </div>
        <div className="p-3">
          <h6 className="fw-bold">Your Bus Has Been Arrived</h6>
          <div style={{ color: 'black' }}>
            At vero eos et accusamus et iusto odio dignis simos ducimus qui
            blanditiis praesentium
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
          className="p-3"
        >
          <div>
            <img src={circleSvg} className="ms-auto" />
          </div>
          <div style={{ marginTop: '10px', color: '#19bc9d' }}>
            <p>2 min ago</p>
          </div>
        </div>
      </div>
      <hr
        style={{
          color: '#e5e5e5',
          height: '3px',
          borderRadius: '2px',
          width: '94%',
          marginLeft: '0 auto',
        }}
      ></hr>
      {/* viewed notification */}
      <div className="d-flex m-4">
        <div className="p-3 pe-1">
          <img
            src={logo}
            alt="icon as per the notification"
            style={{
              width: '30px',
              border: '1px solid black',
              borderRadius: '50%',
            }}
          />
        </div>
        <div className="p-3">
          <h6 className="fw-bold" style={{ color: '#555555' }}>
            Your Bus Has Been Arrived
          </h6>
          <div style={{ color: '#898989' }}>
            At vero eos et accusamus et iusto odio dignis simos ducimus qui
            blanditiis praesentium
          </div>
        </div>
      </div>
      <hr
        style={{
          color: '#f5f5f5',
          height: '3px',
          borderRadius: '2px',
          width: '97%',
          marginLeft: '3%',
        }}
      ></hr>
    </div>
  );
};
