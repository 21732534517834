import bus from '../assets/feedback/bus.svg';
import driver from '../assets/feedback/driver.svg';
import drivingSkill from '../assets/feedback/drivingSkill.svg';
import best from '../assets/emotionIcons/besticon.svg';
import average from '../assets/emotionIcons/Averageicon.svg';
import good from '../assets/emotionIcons/goodicon.svg';
import bad from '../assets/emotionIcons/badicon.svg';

export let feedbackData = [
  {
    title: 'Driver / Conductor behaviour',
    imgSrc: driver,
    optionValue: '1',
  },
  {
    title: 'Bus Condition',
    imgSrc: bus,
    optionValue: '2',
  },
  {
    title: 'Driving',
    imgSrc: drivingSkill,
    optionValue: '3',
  },
];

export let emotionData = [
  {
    title: 'Best',
    rateValue: 'Best',
    imgSrc: best,
  },
  {
    title: 'Good',
    rateValue: 'Good',
    imgSrc: good,
  },
  {
    title: 'Average',
    rateValue: 'Average',
    imgSrc: average,
  },
  {
    title: 'Bad',
    rateValue: 'Bad',
    imgSrc: bad,
  },
];
