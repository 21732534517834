import { useState, useEffect } from 'react';

const useGeolocation = () => {
  const [state, setState] = useState({
    loading: true,
    latitude: null,
    longitude: null,
    timestamp: Date.now(),
  });

  let mounted = true;
  //let watchId;

  const onEvent = (event) => {
    if (mounted) {
      setState((oldState) => ({
        ...oldState,
        loading: false,
        latitude: event.coords.latitude,
        longitude: event.coords.longitude,
      }));
    }
  };

  const onEventError = (error) =>
    mounted && setState((oldState) => ({ ...oldState, loading: false, error }));

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(onEvent, onEventError);
    // watchId = navigator.geolocation.watchPosition(onEvent, onEventError);

    return () => {
      mounted = false;
      //navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  return state;
};

export default useGeolocation;
