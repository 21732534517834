import React from 'react';
import { mmiDataTypes } from '../common/types';
import { NavHome, MmiMap } from '../components';

export const Direction = ({ handleClose, data, currentLocationAddress }) => {
  return (
    <div
      className="position-absolute top-0 start-0 w-100 min-vh-100 "
      style={{ zIndex: 10, backgroundColor: 'white' }}
    >
      <NavHome
        title="Direction"
        goToHome={false}
        handleNonHomeBtn={handleClose}
      />
      <div style={{ width: '100%', height: 'calc(100vh - 52px)' }}>
        <MmiMap
          data={{
            mmiDataType: mmiDataTypes.direction,
            value: [
              {
                label: currentLocationAddress.address,
                geoposition: currentLocationAddress.latlng,
              },
              {
                label: data.address,
                geoposition: data.geometry.coordinates.join(','),
              },
            ],
          }}
        />
      </div>
    </div>
  );
};
