import React, { useState } from 'react';
import { CurrentLocation } from './CurrentLocation';
import { SearchBar } from './SearchBar';
import useLocalStorage from '../hooks/useLocalStorage';

import { SearchList } from './SearchList';

export const SearchLocation = ({
  openCloseHandler,
  clickHandler,
  currentLocation = null,
  allStopsData: searchResult,
  params,
  paramTypeToFilter,
  showRecentMost = true,
  type = null,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const [recentTripHistory, setRecentTripHistory] = useLocalStorage(
    'recentTripHistory',
    []
  );

  const handleClick = function (data) {
    let { id = null } = data;
    if (!id) return;
    let isExists = recentTripHistory.some((d) => d.id === id);
    if (!isExists) {
      if (recentTripHistory.length === 10) {
        setRecentTripHistory([...recentTripHistory.slice(1), data]);
      } else {
        setRecentTripHistory([...recentTripHistory, data]);
      }
    }
    clickHandler(data);
    openCloseHandler();
  };

  const handleInput = function (s) {
    setSearchTerm(s);
  };

  return (
    <div
      className="position-absolute top-0 start-0 min-vw-100 min-vh-100 d-flex flex-column"
      style={{ zIndex: 10 }}
    >
      <SearchBar
        placeholder="Search here..."
        required={false}
        handleClose={openCloseHandler}
        handleInput={handleInput}
      />
      <div className="search-result">
        {currentLocation && (
          <CurrentLocation address={currentLocation.address} />
        )}

        <SearchList
          data={
            searchTerm
              ? searchResult.filter((d) => {
                  if (params[paramTypeToFilter]) {
                    if (d.id === params[paramTypeToFilter]) {
                      return false;
                    }
                  }

                  if (d?.address || d?.name) {
                    let flag = false;
                    if (
                      d?.address &&
                      d.address.toLowerCase().includes(searchTerm.toLowerCase())
                    ) {
                      flag = true;
                    }
                    if (
                      d?.name &&
                      d.name.toLowerCase().includes(searchTerm.toLowerCase())
                    ) {
                      flag = true;
                    }
                    return flag;
                  }
                  return false;
                })
              : showRecentMost
              ? [
                  ...recentTripHistory
                    .filter((d) => d.id !== params[paramTypeToFilter])
                    .reverse(),
                ]
              : null
          }
          handleClick={handleClick}
          type={type ? type : searchTerm ? null : 'recent'}
        />
      </div>
    </div>
  );
};
