import React, { useState } from 'react';
import { Header, MenuItem } from '../components';

import { Routes } from './../routes';
import useLocalStorage from '../hooks/useLocalStorage';
import useGeolocation from './../hooks/useGeolocation';

import mmiBranding from '../assets/mmibranding.png';
import androidLogo from './../assets/android.svg';
import androidapk from './../assets/tsrtc_passenger.apk';
export const HomePage = () => {
  const state = useGeolocation();

  const [isAppView, setIsAppView] = useState(true);

  const [selectedOperationType, setSelectedOperationType] = useLocalStorage(
    'operationType',
    'intra'
  );

  const handleToggleAppView = function () {
    setIsAppView(!isAppView);
  };
  const handleRegionChange = function (type) {
    setSelectedOperationType(type);
  };

  const GenerateLinks = function () {
    return (
      <div className={`card-section ${isAppView ? 'list-view' : ''}`}>
        {Object.entries(Routes[selectedOperationType]).map(
          ([key, { Comp, ...props }]) => (
            <MenuItem key={key} {...props} isAppView={isAppView} />
          )
        )}
      </div>
    );
  };

  return (
    <div className="tsrtc-container">
      <Header isAppView={isAppView} handleToggleAppView={handleToggleAppView} />
      <section className="tab-section">
        <ul className="nav nav-tabs tab_box" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={` ${
                selectedOperationType === 'intra' ? 'active' : ''
              }`}
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
              onClick={() => handleRegionChange('intra')}
            >
              Hyderabad City
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={` ${
                selectedOperationType === 'inter' ? 'active' : ''
              }`}
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              onClick={() => handleRegionChange('inter')}
            >
              District Services
            </button>
          </li>
        </ul>

        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            {GenerateLinks()}
          </div>
        </div>
      </section>

      <div className="login-bt">
        <button>TSRTC User Login</button>
      </div>
      <div className="login-addroid">      
      
       <button> <a href={androidapk}> <img className="android-logo" src={androidLogo} alt="mmi logo" /> Download TSRTC App </a></button>
      </div>
      <div className="branding-section">
        <img src={mmiBranding} alt="mmi logo" />
      </div>
    </div>
  );
};
